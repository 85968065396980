.mainofbalanndhistry {
    width: 60%;
    margin-bottom: 20px;
}

.mainofbalanndhistry .availablebalance {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px;
}

.mainofbalanndhistry .availablebalance .avalletext {
    color: gray;
    font-size: 15px;
    font-weight: 500;
}

.mainofbalanndhistry .availablebalance .balanctext {
    color: black;
    font-size: 16px;
    font-weight: 500;
}

.mainofbalanndhistry .availablebalance .payoutbutton {
    width: calc(100% / 4);
    padding: 10px;
    border-radius: 20px;
    text-align: center;
    background-color: #7752c2;
    color: white;
    font-size: 15px;
    border: none;
    font-weight: bold;
    cursor: pointer;
}

.mainofbalanndhistry .transactiohistory {
    padding: 12px 15px;
    background-color: #efefef;
}

.mainofbalanndhistry .transactiohistory .histortext {
    color: black;
    font-size: 16px;
    font-weight: 600;
}

.refund_history {
    background-color: #efefef;
    padding: 12px 15px;
    width: 60%;
    margin-bottom: 10px;
}


/* Payout style card */
.mainofwallet {
    width: 60%;
    margin-bottom: 30px;
}

.mainpayouts {
    width: 100%;
    border-bottom: 1px solid gray;
    padding: 10px 0px 10px 0px;
}

.mainpayouts .payoutinsidesingle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

/* ///////////////// */


/* Wallet style card */
.mainwallet {
    width: 100%;
    border-bottom: 1px solid gray;
    padding: 10px 0px 10px 0px;
}

.mainwallet .insidewalletsingle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

/* ///////////////// */



/* Loader Data Load */
.loadingfordataload {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* //////////////// */




@media only screen and (min-width: 0px) and (max-width: 431px) {
    .mainofbalanndhistry {
        width: 90%;
        margin-bottom: 5px;
    }

    .mainofbalanndhistry .availablebalance {
        padding: 10px;
    }

    .mainofbalanndhistry .availablebalance .avalletext {
        font-size: 12px;
    }

    .mainofbalanndhistry .availablebalance .balanctext {
        font-size: 13px;
    }

    .mainofbalanndhistry .availablebalance .payoutbutton {
        width: calc(100% / 3);
        padding: 8px;
        border-radius: 18px;
        font-size: 11px;
    }

    .mainofbalanndhistry .transactiohistory {
        padding: 9px 12px;
    }

    .mainofbalanndhistry .transactiohistory .histortext {
        font-size: 12px;
    }

    .refund_history {
        padding: 10px 13px;
        width: 90%;
        margin-bottom: 8px;
    }

    .mainofwallet {
        width: 90%;
        margin-bottom: 15px;
    }

    .mainpayouts {
        padding: 6px 0px 6px 0px;
    }

    .mainpayouts .payoutinsidesingle {
        margin-top: 6px;
    }
}




@media only screen and (min-width: 431px) and (max-width: 680px) {
    .mainofbalanndhistry {
        width: 75%;
        margin-bottom: 10px;
    }

    .mainofbalanndhistry .availablebalance {
        padding: 12px;
    }

    .mainofbalanndhistry .availablebalance .avalletext {
        font-size: 13px;
    }

    .mainofbalanndhistry .availablebalance .balanctext {
        font-size: 14px;
    }

    .mainofbalanndhistry .availablebalance .payoutbutton {
        width: calc(100% / 3.2);
        padding: 9px;
        border-radius: 18px;
        font-size: 12px;
    }

    .mainofbalanndhistry .transactiohistory {
        padding: 10px 13px;
    }

    .mainofbalanndhistry .transactiohistory .histortext {
        font-size: 13px;
    }

    .refund_history {
        padding: 11px 14px;
        width: 75%;
    }

    .mainofwallet {
        width: 75%;
        margin-bottom: 20px;
    }

    .mainpayouts {
        padding: 8px 0px 8px 0px;
    }

    .mainpayouts .payoutinsidesingle {
        margin-top: 8px;
    }
}



@media only screen and (min-width: 680px) and (max-width: 950px) {
    .mainofbalanndhistry {
        width: 65%;
        margin-bottom: 20px;
    }

    .mainofbalanndhistry .availablebalance {
        padding: 13px;
    }

    .mainofbalanndhistry .availablebalance .avalletext {
        font-size: 14px;
    }

    .mainofbalanndhistry .availablebalance .balanctext {
        font-size: 15px;
    }

    .mainofbalanndhistry .availablebalance .payoutbutton {
        width: calc(100% / 3.5);
        font-size: 14px;
    }

    .mainofbalanndhistry .transactiohistory {
        padding: 11px 14px;
    }

    .mainofbalanndhistry .transactiohistory .histortext {
        font-size: 14px;
    }

    .refund_history {
        width: 65%;
    }

    .mainofwallet {
        width: 65%;
        margin-bottom: 20px;
    }

    .mainpayouts {
        padding: 8px 0px 8px 0px;
    }

    .mainpayouts .payoutinsidesingle {
        margin-top: 8px;
    }
}