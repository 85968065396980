.modal_detailBackground {
  background-color: rgba(2, 2, 2, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  position: fixed;
  height: 100%;
}

.modal_detailBackground .modaldetailContainer {
  width: 40%;
  height: 510px;
  border-radius: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
}

.modal_detailBackground .modaldetailContainer::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.modal_detailBackground .modaldetailContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.modal_detailBackground .modaldetailContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #874daf;
}

.modaldetailContainer .titledetailCloseBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modaldetailContainer .titledetailCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modaldetailContainer .titledetailCloseBtn h3 {
  margin-right: 15px;
}

/* //////////////////// */
.active-button {
  width: 100%;
  height: 45px;
  background-color: #874DAF;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.inactive-button {
  background-color: white;
  width: 100%;
  height: 45px;
  display: flex;
  border: none;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
}

.active-text {
  color: white;
}

.inactive-text {
  color: #874DAF;
}

/* //////////////////// */





/* First Card Detail */
.doctor-details-container {
  width: 94%;
  margin: 0px auto;
  margin-top: 10px;
  padding: 15px;
  background-color: white;
  box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
}

.doctor-info {
  display: flex;
  justify-content: space-between;
}

.doctor-name {
  color: black;
  font-weight: bold;
  font-size: 20px;
  max-width: 100%;
}

.doctor-category {
  color: black;
  font-size: 12px;
  max-width: 100%;
  margin-top: 5px;
}

.profile-image-container {
  width: 60px;
  height: 60px;
  border-radius: 15px;
  border: 2px solid #874DAF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image {
  width: 55px;
  height: 55px;
  border-radius: 12px;
  object-fit: cover;
}

.additional-details {
  margin-top: 2;
}

.detail-row {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.icon {
  color: #874DAF;
  font-size: 16px;
}

.detail-text {
  color: black;
  font-size: 12px;
  margin-left: 10px;
  font-weight: bold;
}

.divider {
  width: 100%;
  border-bottom: 1px solid lightgray;
  margin: 10px 0;
}

.patient-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  margin-bottom: 10px;
}

.info-item {
  margin-right: 20px;
}

.info-label {
  color: black;
  font-weight: bold;
  font-size: 12px;
}

.info-value {
  color: black;
  font-size: 12px;
  max-width: 90%;
}

.payment-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}

.payment-label {
  color: black;
  font-size: 14px;
  margin-right: 10px;
  font-weight: bold;
}

.payment-value {
  color: black;
  font-size: 14px;
  font-weight: bold;
}

/* /////////////////////// */




/* Issue show card */
.your-component-container {
  width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.skalton-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  height: 100%;
}

.no-data-container {
  display: flex;
  align-items: center;
  margin-top: 50px;
  justify-content: center;
}

.data-container {
  width: 95%;
  box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
  padding: 15px;
  background-color: white;
}

.divider {
  width: 100%;
  border-bottom: 1px solid lightgray;
  margin-top: 10px;
  margin-bottom: 5px;
}

.detail-row {
  display: flex;
  margin-top: 10px;
}

.detail-row p {
  margin-left: 10px;
}

.detail-row p:first-child {
  margin-left: 0;
  font-weight: bold;
}

/* ///////////////////////////// */

/* 

.modaldetailContainer .imagemodal {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding: 10px 0px 10px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modaldetailContainer img {
  width: 65px;
  height: 65px;
  border-radius: 10px;
  object-fit: cover;
}

.modaldetailContainer .imagemodal .name_and_address {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.modaldetailContainer .imagemodal .name_and_address h3 {
  color: black;
}

.modaldetailContainer .imagemodal .name_and_address h4 {
  color: gray;
}


.modaldetailContainer .deatiylsmodal {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 0px 30px 15px 30px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modaldetailContainer .deatiylsmodal .top_in_detail {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.modaldetailContainer .deatiylsmodal .top_in_detail h3 {
  color: gray;
  font-size: medium;
}

.modaldetailContainer .paymethodmodal {
  width: 100%;
  background-color: #effbfb;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 0px 30px 15px 30px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modaldetailContainer .paymethodmodal .top_in_paymethod {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.modaldetailContainer .paymethodmodal .top_in_paymethod h3 {
  color: black;
  font-size: 14px;
}

.modaldetailContainer .paymethodmodal .top_in_paymethod h4 {
  font-size: 14px;
  color: #874daf;
}

.modal_detailBackground .modaldetailContainer .botommodal {
  width: 100%;
  background-color: #874daf;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 10px 0px 10px 0px;
  margin-top: 30px;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modal_detailBackground .modaldetailContainer .botommodal p {
  color: white;
}

.modal_detailBackground .modaldetailContainer .paymethodmodal .top_in_paymethod .icons {
  color: #874daf;
  margin-right: 10px;
} */




@media only screen and (min-width: 0px) and (max-width: 431px) {
  .modal_detailBackground .modaldetailContainer {
    width: 95%;
    padding: 20px 10px 10px 10px;
  }

  .modaldetailContainer .titledetailCloseBtn button {
    font-size: 16px;
  }

  .modaldetailContainer .titledetailCloseBtn h3 {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 431px) and (max-width: 680px) {
  .modal_detailBackground .modaldetailContainer {
    width: 75%;
    padding: 20px 10px 10px 10px;
  }

  .modaldetailContainer .titledetailCloseBtn button {
    font-size: 18px;
  }

  .modaldetailContainer .titledetailCloseBtn h3 {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 680px) and (max-width: 950px) {
  .modal_detailBackground .modaldetailContainer {
    width: 55%;
    padding: 20px 10px 10px 10px;
  }
}