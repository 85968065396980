.modal_readyreviewground {
    background-color: rgba(2, 2, 2, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    position: fixed;
    height: 100%;
    z-index: 1;
}

.modal_readyreviewground .modalreadyreviewContainer {
    width: 35%;
    height: 510px;
    border-radius: 12px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
}

.modal_readyreviewground .modalreadyreviewContainer::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.modal_readyreviewground .modalreadyreviewContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.modal_readyreviewground .modalreadyreviewContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
}

.modalreadyreviewContainer .titlereadyreviewCloseBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 20px 0px 15px;
}

.modalreadyreviewContainer .titlereadyreviewCloseBtn button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.modalreadyreviewContainer .circleouttime {
    width: 90px;
    height: 90px;
    border-radius: 100px;
    background-color: #874DAF;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
    position: relative;
}

.modalreadyreviewContainer .circleouttime .icninsideclock {
    color: white;
    font-size: 43px;
}

.modalreadyreviewContainer .showhedingtxtrvw {
    font-size: 18px;
    text-align: center;
    color: black;
    font-weight: bold;
    margin-top: 10px;
}

.modalreadyreviewContainer .imageredyrivw {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    align-self: center;
    margin-top: 5px;
}

.modalreadyreviewContainer .readydocnme {
    color: black;
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
    text-align: center;
}

.modalreadyreviewContainer .readycatg {
    color: black;
    font-size: 14px;
    text-align: center;
    margin-top: 3px;
}

.modalreadyreviewContainer .readyaddess {
    color: black;
    font-size: 13px;
    text-align: center;
}

.modalreadyreviewContainer .lastwobtnredtrivw {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 20px;
    padding: 0 15px;
}

.modalreadyreviewContainer .lastwobtnredtrivw .firstbackhmbtn {
    width: 47%;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 100px;
    background-color: #c2ecfc;
    box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
}

.modalreadyreviewContainer .lastwobtnredtrivw .firstbackhmbtn .frsttxtbck {
    color: #874DAF;
    font-weight: bold;
}

.modalreadyreviewContainer .lastwobtnredtrivw .leavereviwbtnscnd {
    width: 48%;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: #874DAF;
    border-radius: 100px;
    border: 1px solid #874DAF;
    box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
}

.modalreadyreviewContainer .lastwobtnredtrivw .leavereviwbtnscnd .levetextreviw {
    color: white;
    font-weight: bold;
}





@media only screen and (min-width: 0px) and (max-width: 431px) {
    .modal_readyreviewground .modalreadyreviewContainer {
        width: 95%;
    }

    .modalreadyreviewContainer .titlereadyreviewCloseBtn {
        padding: 15px 20px 0px 10px;
    }

    .modalreadyreviewContainer .circleouttime {
        width: 85px;
        height: 85px;
    }

    .modalreadyreviewContainer .circleouttime .icninsideclock {
        font-size: 41px;
    }

    .modalreadyreviewContainer .showhedingtxtrvw {
        font-size: 16px;
        margin-top: 10px;
    }

    .modalreadyreviewContainer .imageredyrivw {
        width: 115px;
        height: 115px;
    }

    .modalreadyreviewContainer .readydocnme {
        font-size: 17px;
        margin-top: 10px;
    }

    .modalreadyreviewContainer .readycatg {
        font-size: 12px;
        margin-top: 3px;
    }

    .modalreadyreviewContainer .readyaddess {
        font-size: 11px;
    }

    .modalreadyreviewContainer .lastwobtnredtrivw {
        padding: 0 10px;
    }

    .modalreadyreviewContainer .lastwobtnredtrivw .firstbackhmbtn {
        width: 47%;
        padding: 9px;
    }

    .modalreadyreviewContainer .lastwobtnredtrivw .firstbackhmbtn .frsttxtbck {
        font-size: 13px;
    }

    .modalreadyreviewContainer .lastwobtnredtrivw .leavereviwbtnscnd {
        width: 48%;
        padding: 9px;
    }

    .modalreadyreviewContainer .lastwobtnredtrivw .leavereviwbtnscnd .levetextreviw {
        font-size: 13px;
    }
}



@media only screen and (min-width: 431px) and (max-width: 680px) {
    .modal_readyreviewground .modalreadyreviewContainer {
        width: 75%;
    }

    .modalreadyreviewContainer .titlereadyreviewCloseBtn {
        padding: 15px 20px 0px 10px;
    }

    .modalreadyreviewContainer .circleouttime {
        width: 87px;
        height: 87px;
    }

    .modalreadyreviewContainer .circleouttime .icninsideclock {
        font-size: 42px;
    }

    .modalreadyreviewContainer .showhedingtxtrvw {
        font-size: 17px;
        margin-top: 10px;
    }

    .modalreadyreviewContainer .imageredyrivw {
        width: 115px;
        height: 115px;
    }

    .modalreadyreviewContainer .readydocnme {
        font-size: 18px;
        margin-top: 10px;
    }

    .modalreadyreviewContainer .readycatg {
        font-size: 13px;
        margin-top: 3px;
    }

    .modalreadyreviewContainer .readyaddess {
        font-size: 12px;
    }

    .modalreadyreviewContainer .lastwobtnredtrivw {
        width: 90%;
        margin-top: 25px;
        margin-bottom: 20px;
        padding: 0 15px;
    }

    .modalreadyreviewContainer .lastwobtnredtrivw .firstbackhmbtn {
        width: 47%;
        padding: 10px;
    }

    .modalreadyreviewContainer .lastwobtnredtrivw .firstbackhmbtn .frsttxtbck {
        font-size: 15px;
    }

    .modalreadyreviewContainer .lastwobtnredtrivw .leavereviwbtnscnd {
        width: 48%;
        padding: 10px;
    }

    .modalreadyreviewContainer .lastwobtnredtrivw .leavereviwbtnscnd .levetextreviw {
        font-size: 15px;
    }
}



@media only screen and (min-width: 680px) and (max-width: 950px) {
    .modal_readyreviewground .modalreadyreviewContainer {
        width: 55%;
    }
}