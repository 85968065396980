.modal_paymentdonBackground {
  background-color: rgba(2, 2, 2, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  position: fixed;
  height: 100%;
}

.modal_paymentdonBackground .modalpaymentdonContainer {
  width: 35%;
  height: 510px;
  border-radius: 12px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

.modal_paymentdonBackground .modalpaymentdonContainer::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

.modal_paymentdonBackground .modalpaymentdonContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.modal_paymentdonBackground .modalpaymentdonContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #874daf;
}

.modalpaymentdonContainer .titlepaymentCloseBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 25px 0px 25px;
}

.modalpaymentdonContainer .titlepaymentCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}


/* Appointment Start */
.modalpaymentdonContainer .Start_Appointment {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 85%;
  background-color: white;
  margin-top: 30px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 15px 15px 5px 15px;
  position: relative;
}

.modalpaymentdonContainer .Start_Appointment .done_view {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #874daf;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: -25px;
}

.modalpaymentdonContainer .Start_Appointment h2 {
  margin-top: 10px;
  color: black;
  text-align: center;
}

.modalpaymentdonContainer .Start_Appointment .line {
  height: 1px;
  width: 100%;
  background-color: lightgray;
  border: none;
  margin-top: 10px;
  margin-bottom: 15px;
}

.modalpaymentdonContainer .Start_Appointment .h3spec {
  color: gray;
  font-weight: 400;
  text-align: center;
}

.modalpaymentdonContainer .Start_Appointment .h4spec {
  width: 100%;
  color: gray;
  display: flex;
  text-align: left;
  margin-top: 15px;
}

.modalpaymentdonContainer .Start_Appointment .mention_nd_nmae {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px 10px 5px;
  border-bottom: 1px solid lightgray;
}

.modalpaymentdonContainer .Start_Appointment .mention_nd_nmae p {
  color: gray;
  font-weight: 400;
}

.modalpaymentdonContainer .Start_Appointment .mention_nd_nmae h5 {
  color: black;
  font-weight: 600;
}

.modalpaymentdonContainer .Start_Appointment .lastention_nd_nmae {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px 10px 5px;
}

.modalpaymentdonContainer .Start_Appointment .lastention_nd_nmae p {
  color: gray;
  font-weight: 400;
}

.modalpaymentdonContainer .Start_Appointment .lastention_nd_nmae h5 {
  color: black;
  font-weight: 600;
}

/* ....................... */



/* Consult Start */
.modalpaymentdonContainer .Start_Consult {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 83%;
  background-color: white;
  margin-top: 70px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 15px;
  position: relative;
}

.modalpaymentdonContainer .Start_Consult .done_view {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #874daf;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: -25px;
}

.modalpaymentdonContainer .Start_Consult h2 {
  margin-top: 10px;
  color: black;
  text-align: center;
}

.modalpaymentdonContainer .Start_Consult .line {
  height: 1px;
  width: 100%;
  background-color: lightgray;
  border: none;
  margin-top: 15px;
  margin-bottom: 20px;
}

.modalpaymentdonContainer .Start_Consult .h3spec {
  color: gray;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

.modalpaymentdonContainer .Start_Consult .h4spec {
  color: gray;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

/* /////////////// */
.Consult_Dialog {
  width: 100%;
  height: 100%;
}

.Consult_Dialog .Consult_dialog_toptitl {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 8px;
}

.Consult_Dialog .Consult_dialog_toptitl h4 {
  color: black;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.Consult_Dialog .Consult_dialog_toptitl h5 {
  color: black;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: -7px;
}

.Consult_Dialog .Consult_Ok_nd_Close {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid lightgray;
  margin-top: 10px;
}

.Consult_Dialog .Consult_Ok_nd_Close .Consult_Ok {
  width: 47%;
  color: #874daf;
  font-weight: bold;
}

.Consult_Dialog .Consult_Ok_nd_Close .line {
  border-left: 1px solid lightgray;
  width: 0.5px;
  height: 35px;
}

.Consult_Dialog .Consult_Ok_nd_Close .Consult_Close {
  width: 47%;
  color: #874daf;
  font-weight: bold;
}

/* ....................... */
/* .................................................. */




/* Appointment And Consult Cancel Start */
.modalpaymentdonContainer .Start_Cancel {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 83%;
  background-color: white;
  margin-top: 100px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 15px;
  position: relative;
}

.modalpaymentdonContainer .Start_Cancel .done_view {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: -25px;
}

.modalpaymentdonContainer .Start_Cancel h2 {
  margin-top: 10px;
  color: black;
  text-align: center;
}

.modalpaymentdonContainer .Start_Cancel .line {
  height: 1px;
  width: 100%;
  background-color: lightgray;
  border: none;
  margin-top: 15px;
  margin-bottom: 20px;
}

.modalpaymentdonContainer .Start_Cancel .h3spec {
  color: gray;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  margin-bottom: 20px;
}

/* ............................... */



/* Back Home Buttun */
.modalpaymentdonContainer .confirmmaindiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalpaymentdonContainer .confirmmaindiv .first_confirm_bottn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #874daf;
  padding: 12px 0px 12px 0px;
  width: 85%;
  align-self: center;
  border-radius: 7px;
  margin-top: 20px;
}

.modalpaymentdonContainer .confirmmaindiv .first_confirm_bottn h4 {
  color: white;
}

.modalpaymentdonContainer .last_backbutun {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #874daf;
  padding: 12px 0px 12px 0px;
  width: 85%;
  align-self: center;
  border-radius: 7px;
  position: absolute;
  bottom: 10px;
}

.modalpaymentdonContainer .last_backbutun h4 {
  color: white;
}

.modalpaymentdonContainer .last_cancelbackbutun {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  padding: 12px 0px 12px 0px;
  width: 85%;
  align-self: center;
  border-radius: 7px;
  position: absolute;
  bottom: 15px;
}

.modalpaymentdonContainer .last_cancelbackbutun h4 {
  color: white;
}

/* ................... */






@media only screen and (min-width: 0px) and (max-width: 431px) {
  .modal_paymentdonBackground .modalpaymentdonContainer {
    width: 90%;
  }

  /* Appointment Start */
  .modalpaymentdonContainer .Start_Appointment {
    margin-top: 30px;
    padding: 10px 10px 5px 10px;
  }

  .modalpaymentdonContainer .Start_Appointment h2 {
    margin-top: 10px;
    font-size: 20px;
  }

  .modalpaymentdonContainer .Start_Appointment .line {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .modalpaymentdonContainer .Start_Appointment .h3spec {
    font-size: 13px;
  }

  .modalpaymentdonContainer .Start_Appointment .h4spec {
    font-size: 14px;
    margin-top: 15px;
  }

  .modalpaymentdonContainer .Start_Appointment .mention_nd_nmae {
    padding: 8px 2px 8px 2px;
  }

  .modalpaymentdonContainer .Start_Appointment .mention_nd_nmae p {
    font-size: 14px;
  }

  .modalpaymentdonContainer .Start_Appointment .mention_nd_nmae h5 {
    font-size: 11px;
  }

  .modalpaymentdonContainer .Start_Appointment .lastention_nd_nmae {
    padding: 8px 2px 8px 2px;
  }

  .modalpaymentdonContainer .Start_Appointment .lastention_nd_nmae p {
    font-size: 14px;
  }

  .modalpaymentdonContainer .Start_Appointment .lastention_nd_nmae h5 {
    font-size: 11px;
  }

  /* ....................... */


  /* Appointment And Consult Cancel Start */
  .modalpaymentdonContainer .Start_Cancel {
    width: 85%;
    padding: 10px;
  }

  .modalpaymentdonContainer .Start_Cancel h2 {
    margin-top: 15px;
    font-size: 20px;
  }

  .modalpaymentdonContainer .Start_Cancel .line {
    margin-top: 12px;
    margin-bottom: 20px;
  }

  .modalpaymentdonContainer .Start_Cancel .h3spec {
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
  }

  /* ............................... */




  /* Back Home Buttun */
  .modalpaymentdonContainer .confirmmaindiv .first_confirm_bottn {
    padding: 10px 0px 10px 0px;
    margin-top: 30px;
  }

  .modalpaymentdonContainer .confirmmaindiv .first_confirm_bottn h4 {
    font-size: 12px;
  }

  .modalpaymentdonContainer .last_backbutun {
    padding: 10px 0px 10px 0px;
    bottom: 15px;
  }

  .modalpaymentdonContainer .last_backbutun h4 {
    font-size: 12px;
  }

  .modalpaymentdonContainer .last_cancelbackbutun {
    padding: 10px 0px 10px 0px;
    bottom: 15px;
  }

  .modalpaymentdonContainer .last_cancelbackbutun h4 {
    font-size: 12px;
  }

  /* ................... */
}



@media only screen and (min-width: 431px) and (max-width: 680px) {
  .modal_paymentdonBackground .modalpaymentdonContainer {
    width: 65%;
  }

  /* Appointment Start */
  .modalpaymentdonContainer .Start_Appointment {
    margin-top: 35px;
    padding: 12px 12px 5px 12px;
  }

  .modalpaymentdonContainer .Start_Appointment h2 {
    margin-top: 10px;
    font-size: 22px;
  }

  .modalpaymentdonContainer .Start_Appointment .line {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .modalpaymentdonContainer .Start_Appointment .h3spec {
    font-size: 14px;
  }

  .modalpaymentdonContainer .Start_Appointment .h4spec {
    font-size: 15px;
    margin-top: 15px;
  }

  .modalpaymentdonContainer .Start_Appointment .mention_nd_nmae {
    padding: 9px 3px 9px 3px;
  }

  .modalpaymentdonContainer .Start_Appointment .mention_nd_nmae p {
    font-size: 15px;
  }

  .modalpaymentdonContainer .Start_Appointment .mention_nd_nmae h5 {
    font-size: 12px;
  }

  .modalpaymentdonContainer .Start_Appointment .lastention_nd_nmae {
    padding: 9px 3px 9px 3px;
  }

  .modalpaymentdonContainer .Start_Appointment .lastention_nd_nmae p {
    font-size: 15px;
  }

  .modalpaymentdonContainer .Start_Appointment .lastention_nd_nmae h5 {
    font-size: 12px;
  }

  /* ....................... */


  /* Appointment And Consult Cancel Start */
  .modalpaymentdonContainer .Start_Cancel {
    width: 85%;
    padding: 12px;
  }

  .modalpaymentdonContainer .Start_Cancel h2 {
    font-size: 22px;
  }

  .modalpaymentdonContainer .Start_Cancel .line {
    margin-top: 15px;
  }

  .modalpaymentdonContainer .Start_Cancel .h3spec {
    font-size: 14px;
  }

  /* ............................... */


  /* Back Home Buttun */
  .modalpaymentdonContainer .confirmmaindiv .first_confirm_bottn h4 {
    font-size: 14px;
  }

  .modalpaymentdonContainer .last_backbutun h4 {
    font-size: 14px;
  }

  .modalpaymentdonContainer .last_cancelbackbutun h4 {
    font-size: 14px;
  }

  /* ................... */
}



@media only screen and (min-width: 680px) and (max-width: 950px) {
  .modal_paymentdonBackground .modalpaymentdonContainer {
    width: 50%;
  }


  /* Appointment Start */
  .modalpaymentdonContainer .Start_Appointment {
    margin-top: 40px;
    padding: 12px 12px 5px 12px;
  }

  .modalpaymentdonContainer .Start_Appointment h2 {
    margin-top: 10px;
    font-size: 22px;
  }

  .modalpaymentdonContainer .Start_Appointment .h3spec {
    font-size: 14px;
  }

  .modalpaymentdonContainer .Start_Appointment .h4spec {
    font-size: 15px;
    margin-top: 15px;
  }

  .modalpaymentdonContainer .Start_Appointment .mention_nd_nmae {
    padding: 9px 3px 9px 3px;
  }

  .modalpaymentdonContainer .Start_Appointment .mention_nd_nmae p {
    font-size: 15px;
  }

  .modalpaymentdonContainer .Start_Appointment .mention_nd_nmae h5 {
    font-size: 12px;
  }

  .modalpaymentdonContainer .Start_Appointment .lastention_nd_nmae {
    padding: 9px 3px 9px 3px;
  }

  .modalpaymentdonContainer .Start_Appointment .lastention_nd_nmae p {
    font-size: 15px;
  }

  .modalpaymentdonContainer .Start_Appointment .lastention_nd_nmae h5 {
    font-size: 12px;
  }

  /* ....................... */
}