.modal_cancelappointground {
  background-color: rgba(2, 2, 2, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  position: fixed;
  height: 100%;
  z-index: 1;
}

.modal_cancelappointground .modalcancelapointContainer {
  width: 35%;
  height: 510px;
  border-radius: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

.modal_cancelappointground .modalcancelapointContainer::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

.modal_cancelappointground .modalcancelapointContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.modal_cancelappointground .modalcancelapointContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #874daf;
}

.modalcancelapointContainer .titlecancelapointCloseBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px 0px 15px;
}

.modalcancelapointContainer .titlecancelapointCloseBtn h3 {
  margin-left: 20px;
  font-weight: 500;
}

.modalcancelapointContainer .titlecancelapointCloseBtn button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: 5px;
}

.modalcancelapointContainer .hspecilh3 {
  margin: 15px 0px 5px 20px;
}


.modalcancelapointContainer .cancelcheckbox {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
}

.modalcancelapointContainer .cancelcheckbox h4 {
  color: black;
  font-weight: 400;
  margin-left: 10px;
}

.modalcancelapointContainer .cancelcheckbox .custom_checkbox_inactive {
  width: 20px;
  height: 20px;
  appearance: none;
  border: 2px solid #874daf;
  border-radius: 100px;
  outline: none;
  transition: background-color 0.3s;
}

.modalcancelapointContainer .cancelcheckbox .custom_checkbox_inactive:checked::before {
  content: '';
  display: none;
}

.modalcancelapointContainer .cancelcheckbox .custom_checkbox_active {
  width: 20px;
  height: 20px;
  appearance: none;
  border: 2px solid #874daf;
  border-radius: 100px;
  background-color: #874daf;
  outline: none;
  transition: background-color 0.3s;
}

.modalcancelapointContainer .cancelcheckbox .custom_checkbox_active:checked::before {
  content: '\2713';
  /* Unicode checkmark symbol */
  display: block;
  color: white;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.modalcancelapointContainer .resaon_inpu {
  width: 87%;
  align-self: center;
  background-color: lightgray;
  color: black;
  border-radius: 8px;
  padding: 10px;
  border: none;
  margin-top: 15px;
  display: flex;
  text-align: start;
  height: 80px;
  position: relative;
}


.modalcancelapointContainer .cancel_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #874daf;
  padding: 12px 0px 12px 0px;
  width: 85%;
  align-self: center;
  border-radius: 7px;
  position: absolute;
  bottom: 15px;
}

.modalcancelapointContainer .cancel_btn {
  color: white;
}




/* /////////////// */
.cancelapoint_Dialog {
  width: 100%;
  height: 100%;
}

.cancelapoint_Dialog .done_view {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #874daf;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  align-self: center;
  margin-top: 15px;
}

.cancelapoint_Dialog .Cancel_dialog_toptitl {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 8px;
}

.cancelapoint_Dialog .Cancel_dialog_toptitl h4 {
  color: black;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.cancelapoint_Dialog .Cancel_dialog_toptitl h5 {
  color: black;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: -7px;
}

.cancelapoint_Dialog .cancelapoint_Ok_nd_Close {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid lightgray;
  margin-top: 10px;
}

.cancelapoint_Dialog .cancelapoint_Ok_nd_Close .cancelapoint_Ok {
  width: 100%;
  color: #874daf;
  font-weight: bold;
}

/* ....................... */



/* .......... */
.modalcancelapointContainer .spinner_main_loader {
  width: 100%;
  height: 100%;
  background-color: rgba(2, 2, 2, 0.8);
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.modalcancelapointContainer .spinner_main_loader .spinner_inside {
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 6px;
  box-shadow: 0 5px 15px rgba(32, 32, 32, .3);
}



@media only screen and (min-width: 0px) and (max-width: 431px) {
  .modal_cancelappointground .modalcancelapointContainer {
    width: 90%;
  }

  .modalcancelapointContainer .titlecancelapointCloseBtn {
    padding: 8px 13px 0px 5px;
  }

  .modalcancelapointContainer .titlecancelapointCloseBtn h3 {
    margin-left: 10px;
    font-size: 15px;
  }

  .modalcancelapointContainer .hspecilh3 {
    margin: 8px 0px 5px 10px;
    font-size: 14px;
  }

  .modalcancelapointContainer .cancelcheckbox {
    margin-left: 15px;
  }

  .modalcancelapointContainer .cancelcheckbox h4 {
    font-size: 12px;
    margin-left: 6px;
  }

  .modalcancelapointContainer .cancel_btn {
    padding: 10px 0px 10px 0px;
  }

  .modalcancelapointContainer .cancel_btn {
    font-size: 13px;
  }

  .cancelapoint_Dialog .Cancel_dialog_toptitl h4 {
    font-size: 15px;
  }

  .cancelapoint_Dialog .Cancel_dialog_toptitl h5 {
    font-size: 15px;
    margin-top: -7px;
  }
}




@media only screen and (min-width: 431px) and (max-width: 680px) {
  .modal_cancelappointground .modalcancelapointContainer {
    width: 65%;
  }

  .modalcancelapointContainer .titlecancelapointCloseBtn {
    padding: 10px 15px 0px 7px;
  }

  .modalcancelapointContainer .titlecancelapointCloseBtn h3 {
    margin-left: 10px;
    font-size: 17px;
  }

  .modalcancelapointContainer .hspecilh3 {
    margin: 10px 0px 5px 12px;
    font-size: 15px;
  }

  .modalcancelapointContainer .cancelcheckbox {
    margin-left: 16px;
  }

  .modalcancelapointContainer .cancelcheckbox h4 {
    font-size: 13px;
    margin-left: 8px;
  }

  .modalcancelapointContainer .cancel_btn {
    padding: 12px 0px 12px 0px;
  }

  .modalcancelapointContainer .cancel_btn {
    font-size: 14px;
  }
}



@media only screen and (min-width: 680px) and (max-width: 950px) {
  .modal_cancelappointground .modalcancelapointContainer {
    width: 50%;
  }

  .modalcancelapointContainer .titlecancelapointCloseBtn {
    padding: 10px 15px 0px 7px;
  }

  .modalcancelapointContainer .titlecancelapointCloseBtn h3 {
    margin-left: 10px;
    font-size: 18px;
  }

  .modalcancelapointContainer .hspecilh3 {
    margin: 10px 0px 5px 12px;
    font-size: 16px;
  }

  .modalcancelapointContainer .cancelcheckbox {
    margin-left: 17px;
  }

  .modalcancelapointContainer .cancelcheckbox h4 {
    font-size: 14px;
    margin-left: 8px;
  }

  .modalcancelapointContainer .cancel_btn {
    padding: 12px 0px 12px 0px;
  }

  .modalcancelapointContainer .cancel_btn {
    font-size: 14px;
  }
}