.appointment_startup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}


/* ////////////////////////// */
.appointment_startup .buttonContainer {
  width: 85%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.appointment_startup .buttonContainer .activeButton {
  background-color: #874DAF;
  color: white;
  padding: 12px 45px;
  border-radius: 5px;
  border: 3px solid #874DAF;
}

.appointment_startup .buttonContainer .inactiveButton {
  background-color: white;
  color: #874DAF;
  padding: 12px 45px;
  border-radius: 5px;
  border: 1.5px solid #874DAF;
}

/* ///////////////////// */




/* ............... */
.appointment_startup .viewofchange_btn {
  width: 80%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 25px;
  padding-left: 3px;
  padding-right: 3px;
  gap: 10px;
}

.appointment_startup .viewofchange_btn .active_button {
  background-color: #874daf;
  box-shadow: 0px 2px 10px #c5c5c5;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: center;
}

.appointment_startup .viewofchange_btn .active_button h4 {
  color: white;
}

.appointment_startup .viewofchange_btn .in_active_button {
  background-color: white;
  box-shadow: 0px 2px 10px #c5c5c5;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: center;
}

.appointment_startup .viewofchange_btn .in_active_button h4 {
  color: #874daf;
}

.appointment_startup .viewofchange_btn .in_active_button:hover {
  background-color: #874daf;
  box-shadow: 0px 2px 10px #c5c5c5;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: center;
}

.appointment_startup .viewofchange_btn .in_active_button:hover h4 {
  color: white;
}

/* ............. */


.appointment_startup .skelton_appoint_div {
  width: 80%;
  border-radius: 5px;
}

.appointment_startup .skelton_appoint_div .sklnappointheight {
  height: 230px;
  margin-bottom: 20px;
}

.appointment_startup .one_appointment_card {
  width: 80%;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 15px 15px 10px 15px;
  box-shadow: 0px 2px 10px #c5c5c5;
}

.appointment_startup .one_appointment_card .outsidetyprcheck h4 {
  margin-left: 10px;
  color: #00c480;
}

.appointment_startup .one_appointment_card .imag_nd_nameetc {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.appointment_startup .one_appointment_card .imag_nd_nameetc .pahli_image {
  width: 70px;
  height: 65px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #874daf;
}

.appointment_startup .one_appointment_card .imag_nd_nameetc .pahli_image .ima_apoint_doc {
  width: 57px;
  height: 57px;
  border-radius: 6px;
  object-fit: cover;
}

.appointment_startup .one_appointment_card .imag_nd_nameetc .name_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.appointment_startup .one_appointment_card .imag_nd_nameetc .date_status_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.appointment_startup .one_appointment_card .imag_nd_nameetc .date_status_top h5 {
  color: gray;
}

.appointment_startup .one_appointment_card .categ_detais {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.appointment_startup .one_appointment_card .categ_detais h4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: #4b738c;
}

.appointment_startup .one_appointment_card .Paid_progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.appointment_startup .one_appointment_card .Paid_progress h4 {
  color: #00c480;
}

.appointment_startup .one_appointment_card .Paid_progress .doted_sta {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: #00c480;
  margin-right: 7px;
  margin-top: 3px;
}

.appointment_startup .one_appointment_card .line {
  height: 1px;
  background-color: lightgray;
  border: none;
  margin-top: 15px;
  margin-bottom: 15px;
}


.appointment_startup .one_appointment_card .last_cancel_nd_reschedule {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 100px;
  padding: 10px 20px 10px 20px;
  border: 2px solid #874daf;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_cancel h3 {
  color: #874daf;
  font-size: 16px;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_reschedule {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #874daf;
  border-radius: 100px;
  padding: 10px 20px 10px 20px;
  border: 2px solid #874daf;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_reschedule h3 {
  color: white;
  font-size: 16px;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_submitreview {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray;
  border-radius: 100px;
  padding: 10px 40px 10px 40px;
  border: 2px solid gray;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_submitreview h3 {
  color: white;
  font-size: 16px;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_leavereview {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #874daf;
  border-radius: 100px;
  padding: 10px 40px 10px 40px;
  border: 2px solid #874daf;
}

.appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_leavereview h3 {
  color: white;
  font-size: 16px;
}



/* Rbsheet */
.bottom-sheet-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  transform: translateY(100%);
  transition: transform 0.3s;
}

.bottom-sheet-overlay.open {
  transform: translateY(0);
}

.cancel_sheet_content {
  background-color: white;
  width: 45%;
  padding: 30px;
  border-radius: 40px 40px 0 0;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cancel_sheet_content h2 {
  color: red;
}

.cancel_sheet_content .line {
  height: 1px;
  width: 100%;
  background-color: lightgray;
  border: none;
  margin-top: 15px;
  margin-bottom: 15px;
}

.cancel_sheet_content p {
  color: gray;
  font-weight: bold;
  max-width: 70%;
  text-align: center;
}

.cancel_sheet_content .twobtn_cancel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 10px;
}

.cancel_sheet_content .twobtn_cancel .close {
  background-color: #eef3ff;
  color: #874DAF;
  border-radius: 100px;
  border: none;
  padding: 13px 40px 13px 40px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cancel_sheet_content .twobtn_cancel .yescancel {
  background-color: #874daf;
  color: white;
  border-radius: 100px;
  border: none;
  padding: 13px 40px 13px 40px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}





@media only screen and (min-width: 0px) and (max-width: 431px) {
  .appointment_startup .buttonContainer {
    width: 100%;
    margin: 15px auto;
    gap: 8px;
  }

  .appointment_startup .buttonContainer .activeButton {
    padding: 8px 32px;
    border: 1px solid #874DAF;
  }

  .appointment_startup .buttonContainer .inactiveButton {
    padding: 8px 32px;
    border: 1px solid #874DAF;
  }

  .appointment_startup .viewofchange_btn {
    width: 95%;
    height: 32px;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 0px;
  }

  .appointment_startup .viewofchange_btn .active_button {
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 8px;
  }

  .appointment_startup .viewofchange_btn .active_button h4 {
    font-size: 11px;
  }

  .appointment_startup .viewofchange_btn .in_active_button {
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 8px;
  }

  .appointment_startup .viewofchange_btn .in_active_button h4 {
    font-size: 11px;
  }

  .appointment_startup .skelton_appoint_div {
    width: 95%;
  }

  .appointment_startup .skelton_appoint_div .sklnappointheight {
    height: 150px;
  }

  .appointment_startup .one_appointment_card {
    width: 95%;
    padding: 10px 10px 8px 10px;
  }

  .appointment_startup .one_appointment_card .outsidetyprcheck h4 {
    margin-left: 0px;
    font-size: 13px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .pahli_image {
    width: 62px;
    height: 51px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .pahli_image .ima_apoint_doc {
    width: 45px;
    height: 45px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .name_top h3 {
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .name_top h4 {
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .date_status_top h5 {
    font-size: 11px;
  }

  .appointment_startup .one_appointment_card .categ_detais {
    padding: 0px;
  }

  .appointment_startup .one_appointment_card .categ_detais h4 {
    gap: 2px;
    font-size: 13px;
  }

  .appointment_startup .one_appointment_card .Paid_progress {
    padding-left: 2px;
    padding-right: 0px;
  }

  .appointment_startup .one_appointment_card .Paid_progress h4 {
    font-size: 13px;
  }

  .appointment_startup .one_appointment_card .line {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule {
    padding-left: 5px;
    margin-bottom: 5px;
  }


  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_cancel {
    padding: 6px 8px 6px 8px;
    border: 1px solid #874daf;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_cancel h3 {
    font-size: 13px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_reschedule {
    padding: 6px 8px 6px 8px;
    border: 1px solid #874daf;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_reschedule h3 {
    font-size: 13px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_submitreview {
    padding: 6px 20px 6px 20px;
    border: 1px solid gray;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_submitreview h3 {
    font-size: 13px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_leavereview {
    padding: 6px 20px 6px 20px;
    border: 1px solid #874daf;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_leavereview h3 {
    font-size: 13px;
  }

  .cancel_sheet_content {
    width: 90%;
    padding: 15px;
    border-radius: 50px 50px 0 0;
  }

  .cancel_sheet_content h2 {
    font-size: 16px;
  }

  .cancel_sheet_content .line {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .cancel_sheet_content p {
    font-size: 12px;
    max-width: 80%;
  }

  .cancel_sheet_content .twobtn_cancel {
    margin-top: 8px;
    margin-bottom: 3px;
  }

  .cancel_sheet_content .twobtn_cancel .close {
    padding: 10px 25px 10px 25px;
  }

  .cancel_sheet_content .twobtn_cancel .yescancel {
    padding: 10px 25px 10px 25px;
  }
}



@media only screen and (min-width: 431px) and (max-width: 680px) {
  .appointment_startup .buttonContainer .activeButton {
    padding: 10px 35px;
    border: 2.5px solid #874DAF;
  }

  .appointment_startup .buttonContainer .inactiveButton {
    padding: 10px 35px;
    border: 1.4px solid #874DAF;
  }

  .appointment_startup .viewofchange_btn {
    width: 90%;
    height: 35px;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 0px;
  }

  .appointment_startup .viewofchange_btn .active_button {
    padding-right: 12px;
    padding-left: 12px;
  }

  .appointment_startup .viewofchange_btn .active_button h4 {
    font-size: 13px;
  }

  .appointment_startup .viewofchange_btn .in_active_button {
    padding-right: 12px;
    padding-left: 12px;
  }

  .appointment_startup .viewofchange_btn .in_active_button h4 {
    font-size: 13px;
  }

  .appointment_startup .skelton_appoint_div {
    width: 90%;
  }

  .appointment_startup .skelton_appoint_div .sklnappointheight {
    height: 180px;
  }

  .appointment_startup .one_appointment_card {
    width: 90%;
    padding: 12px 12px 10px 12px;
  }

  .appointment_startup .one_appointment_card .outsidetyprcheck h4 {
    margin-left: 5px;
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .pahli_image {
    width: 63px;
    height: 55px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .pahli_image .ima_apoint_doc {
    width: 47px;
    height: 47px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .name_top h3 {
    font-size: 15px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .name_top h4 {
    font-size: 15px;
  }

  .appointment_startup .one_appointment_card .imag_nd_nameetc .date_status_top h5 {
    font-size: 12px;
  }

  .appointment_startup .one_appointment_card .categ_detais {
    padding-left: 3px;
    padding-right: 5px;
  }

  .appointment_startup .one_appointment_card .categ_detais h4 {
    gap: 3px;
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .Paid_progress {
    padding-left: 5px;
    padding-right: 5px;
  }

  .appointment_startup .one_appointment_card .Paid_progress h4 {
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .line {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule {
    padding-left: 10px;
    margin-bottom: 5px;
  }


  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_cancel {
    padding: 8px 10px 8px 10px;
    border: 1.5px solid #874daf;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_cancel h3 {
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_reschedule {
    padding: 8px 10px 8px 10px;
    border: 1.5px solid #874daf;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_reschedule h3 {
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_submitreview {
    padding: 8px 30px 8px 30px;
    border: 1.5px solid gray;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_submitreview h3 {
    font-size: 14px;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_leavereview {
    padding: 8px 30px 8px 30px;
    border: 1.5px solid #874daf;
  }

  .appointment_startup .one_appointment_card .last_cancel_nd_reschedule .last_leavereview h3 {
    font-size: 14px;
  }

  .cancel_sheet_content {
    width: 75%;
    padding: 15px;
    border-radius: 50px 50px 0 0;
  }

  .cancel_sheet_content h2 {
    font-size: 16px;
  }

  .cancel_sheet_content .line {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .cancel_sheet_content p {
    font-size: 12px;
    max-width: 80%;
  }

  .cancel_sheet_content .twobtn_cancel {
    margin-top: 8px;
    margin-bottom: 3px;
  }

  .cancel_sheet_content .twobtn_cancel .close {
    padding: 10px 30px 10px 30px;
  }

  .cancel_sheet_content .twobtn_cancel .yescancel {
    padding: 10px 30px 10px 30px;
  }
}



@media only screen and (min-width: 680px) and (max-width: 950px) {
  .cancel_sheet_content {
    width: 55%;
    padding: 20px;
    border-radius: 50px 50px 0 0;
  }

  .cancel_sheet_content h2 {
    font-size: 16px;
  }

  .cancel_sheet_content .line {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .cancel_sheet_content p {
    font-size: 14px;
    max-width: 80%;
  }

  .cancel_sheet_content .twobtn_cancel {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .cancel_sheet_content .twobtn_cancel .close {
    padding: 12px 38px 12px 38px;
  }

  .cancel_sheet_content .twobtn_cancel .yescancel {
    padding: 12px 38px 12px 38px;
  }
}