.Doctor_head {
  background-color: #eaeef3;
  /* padding: 20px; */
  width: 100%;
  /* height: 100vh; */
}

.Doctor_head .toptextprof {
  margin-left: 20px;
  margin-top: 20px;
}

.All_in_this {
  width: 100%;
  padding: 20px;
  display: flex;
  margin-top: 10px;
}



/* Profile Card */
.first_column {
  display: inline-block;
  width: 70%;
}

.Profile_card {
  display: flex;
  width: 95%;
  height: 310px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1.5px solid #00000017;
}

.Profile_card .img_wrapper {
  overflow: hidden;
  width: 33%;
}

.Profile_card img {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  transition: all 0.3s ease 0s;
}

.Profile_card img:hover {
  transform: scale(1.1);
}

.profile_detail {
  width: 60%;
  background-color: white;
  padding-top: 10px;
  margin-left: 15px;
}

.profile_detail .nameofdoc {
  color: #135d64;
}

.profile_detail .star {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fcb71b;
}

.profile_detail .star span {
  color: gray;
  margin-left: 10px;
}

.profile_detail .cateogdoc {
  color: #135d64;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;
}

.profile_detail .just_row {
  display: flex;
  margin-top: 30px;
}

.profile_detail .just_row i {
  color: #135d64;
}

.profile_detail .just_row h4 {
  color: gray;
  margin-left: 10px;
  font-size: 18px;
}

.profile_detail .just_row_2 {
  display: flex;
  margin-top: 20px;
}

.profile_detail .just_row_2 i {
  color: #135d64;
}

.profile_detail .just_row_2 h4 {
  color: gray;
  margin-left: 10px;
  font-size: 20px;
}

/*  */






/* Chanebtn Card */
.chanebtn_card {
  width: 95%;
  background-color: white;
  margin-top: 20px;
  height: 80px;
  border: 1.5px solid #00000017;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.chanebtn_card .about_me_btn_active a {
  width: 110px;
  height: 45px;
  border-radius: 40px;
  color: white;
  background-color: #e91e63;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  box-shadow: 0 5px 15px rgba(32, 32, 32, .3);
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  transition: 0.3s ease-in-out
}

.chanebtn_card .about_me_btn_inactive a {
  width: 110px;
  height: 45px;
  border-radius: 40px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  text-decoration: none;
  color: gray;
  font-weight: bold;
  font-size: 16px;
  transition: 0.3s ease-in-out
}

.chanebtn_card .about_me_btn_inactive a:hover {
  background-color: #e91e63;
  color: white;
}

/*  */




/* About in changebtn */
.About_in_btn {
  width: 95%;
  margin-top: 15px;
  background-color: white;
  padding: 20px 25px 20px 25px;
  opacity: 100px;
  box-shadow: 0 5px 15px rgba(32, 32, 32, .3);
  border-radius: 10px;
}

.About_in_btn hr {
  margin-top: 15px;
}

.About_in_btn .numer_licen h3 {
  color: #9a7a6b;
  margin-top: 20px;
  font-size: 20px;
}

.About_in_btn .img_licen h3 {
  color: #9a7a6b;
  margin-top: 20px;
  font-size: 20px;
}

.About_in_btn .img_licen img {
  width: 250px;
  height: 220px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 20px;
  border: 3px solid #1a6aff;
}

.About_in_btn .numer_licen h4 {
  margin-left: 15px;
  margin-top: 10px;
  font-size: 20px;
}

.About_in_btn .numer_licen hr {
  margin-bottom: 10px;
}




/* Review Card */
.Review_Card {
  width: 95%;
  margin-top: 15px;
  background-color: white;
  padding: 20px 25px 30px 25px;
  opacity: 100px;
  box-shadow: 0 5px 15px rgba(32, 32, 32, .3);
  border-radius: 10px;
}

.Review_Card hr {
  margin-top: 15px;
}

.Review_Card .Noreview {
  display: block;
  text-align: center;
  margin-top: 30px;
}

.Review_Card .review_all {
  width: 100%;
  margin-top: 30px;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid whitesmoke;
  display: flex;
}

.Review_Card .review_all img {
  width: 110px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
}

.Review_Card .review_all .review_details {
  padding: 0px 20px 0px 20px;
  width: 100%;
}

.Review_Card .review_all .review_details p {
  margin-top: 10px;
  color: gray;
}

.Review_Card .review_all .review_details .bottom_deatil_review {
  display: flex;
  align-items: center;
  margin-top: 10px;
  text-align: center;
}

.Review_Card .review_all .review_details .bottom_deatil_review .star {
  color: #fcb71b;
  margin-left: 10px;
}

/*  */



/* About Card */
.second_column {
  width: 30%;
  display: inline-block;
}

/* Map Card */
.second_column .Map_Card {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1.5px solid #00000017;
  margin-bottom: 20px;
}

.second_column .Map_Card .address_head {
  width: 100%;
  padding-left: 20px;
  border-top: 4px solid #fcb71b;
}

.second_column .Map_Card .address_head h3 {
  color: black;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 22px;
}

.second_column .Map_Card h4 {
  color: black;
  margin: 5px 15px 5px 15px;
}

.second_column .Map_Card .address_paragraph {
  margin-top: 15px;
  width: 100%;
  height: 340px;
}



.About_card {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1.5px solid #00000017;
}

.About_card .about_head {
  width: 100%;
  padding-left: 20px;
  border-top: 4px solid #fcb71b;
}

.second_column .About_card .about_head h3 {
  color: black;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 22px;
}

.about_data {
  padding-left: 20px;
  padding-right: 20px;
}

.about_data p {
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.about_data hr {
  font-weight: 300;
}

.about_dat_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 15px;
}

.about_dat_row h4 {
  color: gray;
  font-size: 15px;
}

.second_column .About_card h3 {
  font-size: 15px;
}

.about_last_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 30px;
  padding-left: 5px;
  padding-right: 5px;
}

.about_last_section {
  text-align: center;
}

.about_last_row .about_last_section h3 {
  font-size: 25px;
  color: #5b9bd1;
}

.about_last_row .about_last_section h4 {
  font-size: 13px;
  color: #5b9bd1;
  margin-top: 5px;
}

/*  */


/* Book Appointment Buttun */
.book_buttun {
  margin-top: 20px;
  width: 100%;
}

.book_buttun .book {
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  margin: 5px;
  color: white;
  border: 1px solid #7752c2;
  font-size: medium;
  transition: 0.3s ease-in-out;
  background-image: linear-gradient(to right, #7752c2, #a289d6);
  box-shadow: 0 5px 15px rgba(32, 32, 32, .3);
}

.book_buttun .book:hover {
  background-image: linear-gradient(to right, white, white);
  color: #7752c2;
}

/* /////////////////// */







@media only screen and (min-width: 0px) and (max-width: 431px) {
  .Doctor_head .toptextprof {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 16px;
  }

  .All_in_this {
    flex-direction: column;
    padding: 10px;
    margin-top: 0px;
  }

  .first_column {
    width: 100%;
  }

  .Profile_card {
    width: 100%;
    height: 200px;
  }

  .Profile_card .img_wrapper {
    width: 45%;
  }

  .profile_detail {
    width: 50%;
    padding-top: 2px;
    margin-left: 7px;
  }

  .profile_detail .nameofdoc {
    font-size: 17px;
  }

  .profile_detail .star {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .profile_detail .star span {
    font-size: 13px;
    margin-left: 3px;
  }

  .profile_detail .cateogdoc {
    font-size: 14px;
    width: 100%;
  }

  .profile_detail .just_row {
    margin-top: 15px;
  }

  .profile_detail .just_row i {
    font-size: 13px;
  }

  .profile_detail .just_row h4 {
    margin-left: 5px;
    font-size: 13px;
  }

  .profile_detail .just_row_2 {
    display: flex;
    margin-top: 16px;
  }

  .profile_detail .just_row_2 i {
    font-size: 13px;
  }

  .profile_detail .just_row_2 h4 {
    margin-left: 5px;
    font-size: 14px;
  }


  /* Chanebtn Card */
  .chanebtn_card {
    width: 100%;
    margin-top: 10px;
    height: 60px;
    padding-left: 10px;
  }

  .chanebtn_card .about_me_btn_active a {
    width: 100px;
    height: 35px;
    margin-right: 7px;
    font-size: 14px;
  }

  .chanebtn_card .about_me_btn_inactive a {
    width: 100px;
    height: 35px;
    margin-right: 7px;
    font-size: 14px;
  }

  /*  */

  /* About in changebtn */
  .About_in_btn {
    width: 100%;
    margin-top: 10px;
    padding: 10px 13px 10px 13px;
  }

  .About_in_btn .aboutmaintext {
    font-size: 15px;
  }

  .About_in_btn hr {
    margin-top: 8px;
  }

  .About_in_btn .numer_licen h3 {
    margin-top: 8px;
    font-size: 15px;
  }

  .About_in_btn .numer_licen h4 {
    margin-left: 3px;
    margin-top: 3px;
    font-size: 15px;
  }

  .About_in_btn .numer_licen hr {
    margin-bottom: 8px;
  }

  .About_in_btn .img_licen h3 {
    margin-top: 8px;
    font-size: 15px;
  }

  .About_in_btn .img_licen img {
    width: 190px;
    height: 160px;
    margin-top: 8px;
    margin-left: 8px;
    border: 2px solid #1a6aff;
  }

  /* Review Card */
  .Review_Card {
    width: 100%;
    margin-top: 8px;
    padding: 10px 13px 10px 13px;
  }

  .Review_Card .revewmaintext {
    font-size: 15px;
  }

  .Review_Card hr {
    margin-top: 8px;
  }

  .Review_Card .Noreview {
    margin-top: 15px;
  }

  .Review_Card .Noreview h4 {
    font-size: 13px;
  }

  .Review_Card .review_all {
    margin-top: 10px;
    padding: 8px;
  }

  .Review_Card .review_all img {
    width: 80px;
    height: 65px;
  }

  .Review_Card .review_all .review_details {
    padding: 0px 8px 0px 8px;
  }

  .Review_Card .review_all .review_details .nameofuserrev {
    font-size: 14px;
  }

  .Review_Card .review_all .review_details p {
    margin-top: 3px;
    font-size: 12px;
  }

  .Review_Card .review_all .review_details .bottom_deatil_review {
    margin-top: 1px;
  }

  .Review_Card .review_all .review_details .bottom_deatil_review h3 {
    font-size: 13px;
  }

  .Review_Card .review_all .review_details .bottom_deatil_review .star {
    margin-left: 3px;
  }

  .second_column {
    width: 100%;
  }

  /* Map Card */
  .second_column .Map_Card {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .second_column .Map_Card .address_head {
    padding-left: 13px;
    border-top: 2.5px solid #fcb71b;
  }

  .second_column .Map_Card .address_head h3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 17px;
  }

  .second_column .Map_Card h4 {
    font-size: 14px;
    color: black;
    margin: 5px 10px 5px 10px;
  }

  .second_column .Map_Card .address_paragraph {
    margin-top: 8px;
    width: 100%;
    height: 250px;
  }

  .About_card .about_head {
    padding-left: 13px;
    border-top: 2.5px solid #fcb71b;
  }

  .second_column .About_card .about_head h3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 17px;
  }

  .about_data {
    padding-left: 13px;
    padding-right: 13px;
  }

  .about_data p {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
  }

  .about_data hr {
    font-weight: 300;
  }

  .about_dat_row {
    margin-top: 5px;
    margin-bottom: 8px;
  }

  .about_dat_row h4 {
    font-size: 12px;
  }

  .second_column .About_card h3 {
    font-size: 13px;
  }

  .about_last_row {
    margin-top: 8px;
    margin-bottom: 15px;
    padding-left: 1px;
    padding-right: 1px;
  }

  .about_last_row .about_last_section h3 {
    font-size: 17px;
  }

  .about_last_row .about_last_section h4 {
    font-size: 11px;
    margin-top: 2px;
  }

  /*  */

  /* Book Appointment Buttun */
  .book_buttun {
    margin-top: 10px;
  }

  .book_buttun .book {
    padding: 10px;
    margin: 3px;
    font-size: small;
  }

  /* /////////////////// */
}




@media only screen and (min-width: 431px) and (max-width: 680px) {
  .Doctor_head .toptextprof {
    margin-left: 15px;
    margin-top: 15px;
    font-size: 17px;
  }

  .All_in_this {
    flex-direction: column;
    padding: 15px;
    margin-top: 0px;
  }

  .first_column {
    width: 100%;
  }

  .Profile_card {
    width: 100%;
    height: 240px;
  }

  .Profile_card .img_wrapper {
    width: 45%;
  }

  .profile_detail {
    width: 50%;
    padding-top: 5px;
    margin-left: 10px;
  }

  .profile_detail .nameofdoc {
    font-size: 19px;
  }

  .profile_detail .star {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .profile_detail .star span {
    font-size: 14px;
    margin-left: 5px;
  }

  .profile_detail .cateogdoc {
    font-size: 16px;
    width: 100%;
  }

  .profile_detail .just_row {
    margin-top: 20px;
  }

  .profile_detail .just_row i {
    font-size: 16px;
  }

  .profile_detail .just_row h4 {
    margin-left: 7px;
    font-size: 16px;
  }

  .profile_detail .just_row_2 {
    display: flex;
    margin-top: 18px;
  }

  .profile_detail .just_row_2 i {
    font-size: 16px;
  }

  .profile_detail .just_row_2 h4 {
    margin-left: 7px;
    font-size: 16px;
  }


  /* Chanebtn Card */
  .chanebtn_card {
    width: 100%;
    margin-top: 10px;
    height: 65px;
    padding-left: 15px;
  }

  .chanebtn_card .about_me_btn_active a {
    width: 105px;
    height: 40px;
    margin-right: 10px;
    font-size: 15px;
  }

  .chanebtn_card .about_me_btn_inactive a {
    width: 105px;
    height: 40px;
    margin-right: 10px;
    font-size: 15px;
  }

  /*  */

  /* About in changebtn */
  .About_in_btn {
    width: 100%;
    margin-top: 10px;
    padding: 13px 15px 13px 15px;
  }

  .About_in_btn .aboutmaintext {
    font-size: 16px;
  }

  .About_in_btn hr {
    margin-top: 10px;
  }

  .About_in_btn .numer_licen h3 {
    margin-top: 10px;
    font-size: 16px;
  }

  .About_in_btn .numer_licen h4 {
    margin-left: 5px;
    margin-top: 5px;
    font-size: 16px;
  }

  .About_in_btn .numer_licen hr {
    margin-bottom: 10px;
  }

  .About_in_btn .img_licen h3 {
    margin-top: 10px;
    font-size: 16px;
  }

  .About_in_btn .img_licen img {
    width: 210px;
    height: 180px;
    margin-top: 10px;
    margin-left: 10px;
    border: 2px solid #1a6aff;
  }

  /* Review Card */
  .Review_Card {
    width: 100%;
    margin-top: 10px;
    padding: 13px 15px 13px 15px;
  }

  .Review_Card .revewmaintext {
    font-size: 16px;
  }

  .Review_Card hr {
    margin-top: 10px;
  }

  .Review_Card .Noreview {
    margin-top: 20px;
  }

  .Review_Card .Noreview h4 {
    font-size: 14px;
  }

  .Review_Card .review_all {
    margin-top: 15px;
    padding: 10px;
  }

  .Review_Card .review_all img {
    width: 90px;
    height: 75px;
  }

  .Review_Card .review_all .review_details {
    padding: 0px 10px 0px 10px;
  }

  .Review_Card .review_all .review_details .nameofuserrev {
    font-size: 15px;
  }

  .Review_Card .review_all .review_details p {
    margin-top: 5px;
    font-size: 13px;
  }

  .Review_Card .review_all .review_details .bottom_deatil_review {
    margin-top: 3px;
  }

  .Review_Card .review_all .review_details .bottom_deatil_review h3 {
    font-size: 14px;
  }

  .Review_Card .review_all .review_details .bottom_deatil_review .star {
    margin-left: 5px;
  }

  .second_column {
    width: 100%;
  }

  /* Map Card */
  .second_column .Map_Card {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .second_column .Map_Card .address_head {
    padding-left: 15px;
    border-top: 3px solid #fcb71b;
  }

  .second_column .Map_Card .address_head h3 {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 18px;
  }

  .second_column .Map_Card h4 {
    font-size: 15px;
    color: black;
    margin: 7px 12px 7px 12px;
  }

  .second_column .Map_Card .address_paragraph {
    margin-top: 10px;
    width: 100%;
    height: 280px;
  }

  .About_card .about_head {
    padding-left: 15px;
    border-top: 3px solid #fcb71b;
  }

  .second_column .About_card .about_head h3 {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 18px;
  }

  .about_data {
    padding-left: 15px;
    padding-right: 15px;
  }

  .about_data p {
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
  }

  .about_data hr {
    font-weight: 300;
  }

  .about_dat_row {
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .about_dat_row h4 {
    font-size: 13px;
  }

  .second_column .About_card h3 {
    font-size: 14px;
  }

  .about_last_row {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .about_last_row .about_last_section h3 {
    font-size: 18px;
  }

  .about_last_row .about_last_section h4 {
    font-size: 12px;
    margin-top: 3px;
  }

  /*  */

  /* Book Appointment Buttun */
  .book_buttun {
    margin-top: 15px;
  }

  .book_buttun .book {
    padding: 13px;
    margin: 3px;
    font-size: small;
  }

  /* /////////////////// */
}






@media only screen and (min-width: 680px) and (max-width: 950px) {
  .Doctor_head .toptextprof {
    margin-left: 15px;
    margin-top: 15px;
    font-size: 20px;
  }

  .All_in_this {
    padding: 15px;
    margin-top: 0px;
  }

  .first_column {
    width: 65%;
  }

  .Profile_card {
    height: 250px;
  }

  .Profile_card .img_wrapper {
    width: 40%;
  }

  .profile_detail {
    width: 50%;
    padding-top: 5px;
    margin-left: 10px;
  }

  .profile_detail .nameofdoc {
    font-size: 21px;
  }

  .profile_detail .star {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .profile_detail .star span {
    font-size: 15px;
    margin-left: 7px;
  }

  .profile_detail .cateogdoc {
    font-size: 17px;
    width: 100%;
  }

  .profile_detail .just_row {
    margin-top: 23px;
  }

  .profile_detail .just_row i {
    font-size: 16px;
  }

  .profile_detail .just_row h4 {
    margin-left: 7px;
    font-size: 16px;
  }

  .profile_detail .just_row_2 {
    display: flex;
    margin-top: 18px;
  }

  .profile_detail .just_row_2 i {
    font-size: 16px;
  }

  .profile_detail .just_row_2 h4 {
    margin-left: 7px;
    font-size: 16px;
  }


  /* Chanebtn Card */
  .chanebtn_card {
    margin-top: 15px;
    height: 70px;
    padding-left: 15px;
  }

  .chanebtn_card .about_me_btn_active a {
    width: 105px;
    height: 43px;
    margin-right: 13px;
    font-size: 15px;
  }

  .chanebtn_card .about_me_btn_inactive a {
    width: 105px;
    height: 43px;
    margin-right: 13px;
    font-size: 15px;
  }

  /*  */

  /* About in changebtn */
  .About_in_btn {
    margin-top: 10px;
    padding: 15px 20px 15px 20px;
  }

  .About_in_btn .aboutmaintext {
    font-size: 17px;
  }

  .About_in_btn hr {
    margin-top: 13px;
  }

  .About_in_btn .numer_licen h3 {
    margin-top: 15px;
    font-size: 18px;
  }

  .About_in_btn .numer_licen h4 {
    margin-left: 10px;
    margin-top: 8px;
    font-size: 18px;
  }

  .About_in_btn .numer_licen hr {
    margin-bottom: 10px;
  }

  .About_in_btn .img_licen h3 {
    margin-top: 15px;
    font-size: 18px;
  }

  .About_in_btn .img_licen img {
    width: 230px;
    height: 200px;
    margin-top: 10px;
    margin-left: 15px;
    border: 2.5px solid #1a6aff;
  }

  /* Review Card */
  .Review_Card {
    margin-top: 10px;
    padding: 15px 20px 25px 20px;
  }

  .Review_Card .revewmaintext {
    font-size: 17px;
  }

  .Review_Card hr {
    margin-top: 13px;
  }

  .Review_Card .Noreview {
    margin-top: 25px;
  }

  .Review_Card .Noreview h4 {
    font-size: 15px;
  }

  .Review_Card .review_all {
    margin-top: 20px;
    padding: 12px;
  }

  .Review_Card .review_all img {
    width: 100px;
    height: 85px;
  }

  .Review_Card .review_all .review_details {
    padding: 0px 13px 0px 13px;
  }

  .Review_Card .review_all .review_details .nameofuserrev {
    font-size: 16px;
  }

  .Review_Card .review_all .review_details p {
    margin-top: 7px;
    font-size: 14px;
  }

  .Review_Card .review_all .review_details .bottom_deatil_review {
    margin-top: 5px;
  }

  .Review_Card .review_all .review_details .bottom_deatil_review h3 {
    font-size: 15px;
  }

  .Review_Card .review_all .review_details .bottom_deatil_review .star {
    margin-left: 7px;
  }

  .second_column {
    width: 35%;
  }

  /* Map Card */
  .second_column .Map_Card {
    margin-bottom: 15px;
  }

  .second_column .Map_Card .address_head {
    padding-left: 15px;
    border-top: 3px solid #fcb71b;
  }

  .second_column .Map_Card .address_head h3 {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 18px;
  }

  .second_column .Map_Card h4 {
    font-size: 14px;
    color: black;
    margin: 5px 10px 5px 10px;
  }

  .second_column .Map_Card .address_paragraph {
    margin-top: 10px;
    width: 100%;
    height: 280px;
  }

  .About_card .about_head {
    padding-left: 15px;
    border-top: 3px solid #fcb71b;
  }

  .second_column .About_card .about_head h3 {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 18px;
  }

  .about_data {
    padding-left: 15px;
    padding-right: 15px;
  }

  .about_data p {
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
  }

  .about_data hr {
    font-weight: 300;
  }

  .about_dat_row {
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .about_dat_row h4 {
    font-size: 13px;
  }

  .second_column .About_card h3 {
    font-size: 14px;
  }

  .about_last_row {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .about_last_row .about_last_section h3 {
    font-size: 18px;
  }

  .about_last_row .about_last_section h4 {
    font-size: 12px;
    margin-top: 3px;
  }

  /*  */

  /* Book Appointment Buttun */
  .book_buttun {
    margin-top: 15px;
  }

  .book_buttun .book {
    padding: 13px;
    margin: 3px;
    font-size: small;
  }

  /* /////////////////// */
}