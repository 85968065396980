.consult_patient_startup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.consult_toptwo_tutton {
    width: 40%;
    background-color: white;
    border-radius: 10px;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
    padding-top: 3px;
    padding-bottom: 3px;
}

.consult_toptwo_tutton .activeProcess {
    width: 49%;
    height: 45px;
    background-color: #874DAF;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.consult_toptwo_tutton .inactiveProcess {
    background-color: white;
    width: 49%;
    height: 45px;
    display: flex;
    border: none;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
}

.consult_toptwo_tutton .processtext {
    color: white;
}

.consult_toptwo_tutton .inprocesstext {
    color: #874DAF;
}



/* Card flatlist */
.consult_patient_startup .skelton_appoint_div {
    width: 80%;
    margin: 20px auto;
}

.consult_patient_startup .skelton_appoint_div .sklnconsltheight {
    height: 200px;
    border-radius: 10px;
    margin-bottom: 20px;
}


.consult_patient_startup .mainpatientinside {
    width: 80%;
    background-color: white;
    border-radius: 10px;
    margin: 20px auto;
    box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
    padding: 15px;
}

.consult_patient_startup .mainpatientinside .firstrowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst {
    display: flex;
    align-items: center;
    gap: 10px;
}

.consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst #userconsult {
    color: black;
    font-size: 15px;
}

.consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst #datee2 {
    color: #874DAF;
    font-size: 12px;
}

.consult_patient_startup .mainpatientinside .firstrowContainer .borderedButton {
    background-color: white;
    border-radius: 8px;
    padding: 8px;
    border: 2px solid #874DAF;
}


.consult_patient_startup .mainpatientinside .profileContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 20px;
}

.consult_patient_startup .mainpatientinside .profileContainer .profileImageContainer {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    border: 2px solid #874DAF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.consult_patient_startup .mainpatientinside .profileContainer .profileImageContainer .imagemainin {
    width: 55px;
    height: 55px;
    border-radius: 100px;
}

.consult_patient_startup .mainpatientinside .profileContainer .columnContainer {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-top: 4px;
}

.consult_patient_startup .mainpatientinside .profileContainer .columnContainer #doctornaemconslt {
    color: black;
    font-weight: bold;
    font-size: 15px;
}

.consult_patient_startup .mainpatientinside .profileContainer .columnContainer #docadrsconslt {
    color: #39b4cc;
    font-size: 15px;
}

.consult_patient_startup .mainpatientinside .dateTimeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 50px;
    gap: 5px;
    margin-top: 10px;
}

.consult_patient_startup .mainpatientinside .dateTimeContainer #Calendarmonthcon {
    font-size: 14px;
    color: #4b738c;
}

.consult_patient_startup .mainpatientinside .statusContainer {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    margin-top: 4px;
}

.consult_patient_startup .mainpatientinside .statusContainer #feeinconsult {
    color: black;
    font-weight: bold;
    font-size: 15px;
}




@media only screen and (min-width: 0px) and (max-width: 431px) {
    .consult_toptwo_tutton {
        width: 70%;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .consult_toptwo_tutton .activeProcess {
        width: 49%;
        height: 40px;
    }

    .consult_toptwo_tutton .inactiveProcess {
        background-color: white;
        width: 49%;
        height: 40px;
    }

    .consult_toptwo_tutton .processtext {
        font-size: 11px;
    }

    .consult_toptwo_tutton .inprocesstext {
        font-size: 11px;
    }

    .consult_patient_startup .skelton_appoint_div {
        width: 95%;
        margin: 5px auto;
    }

    .consult_patient_startup .skelton_appoint_div .sklnconsltheight {
        height: 140px;
        margin-bottom: 20px;
    }

    .consult_patient_startup .mainpatientinside {
        width: 95%;
        margin: 5px auto;
        padding: 10px;
    }

    .consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst {
        gap: 4px;
    }

    .consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst #userconsult {
        font-size: 12px;
    }

    .consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst #datee2 {
        font-size: 9px;
    }

    .consult_patient_startup .mainpatientinside .firstrowContainer .borderedButton {
        padding: 4px;
        border-radius: 5px;
        border: 1.5px solid #874DAF;
        font-size: 11px;
    }


    .consult_patient_startup .mainpatientinside .profileContainer {
        padding-top: 5px;
    }

    .consult_patient_startup .mainpatientinside .profileContainer .profileImageContainer {
        width: 50px;
        height: 50px;
        border: 1.5px solid #874DAF;
    }

    .consult_patient_startup .mainpatientinside .profileContainer .profileImageContainer .imagemainin {
        width: 45px;
        height: 45px;
    }

    .consult_patient_startup .mainpatientinside .profileContainer .columnContainer {
        margin-left: 5px;
        margin-top: 0px;
    }

    .consult_patient_startup .mainpatientinside .profileContainer .columnContainer #doctornaemconslt {
        font-size: 13px;
    }

    .consult_patient_startup .mainpatientinside .profileContainer .columnContainer #docadrsconslt {
        color: #39b4cc;
        font-size: 13px;
    }

    .consult_patient_startup .mainpatientinside .dateTimeContainer {
        margin-left: 35px;
        margin-top: 6px;
    }

    .consult_patient_startup .mainpatientinside .dateTimeContainer #Calendarmonthcon {
        font-size: 12px;
    }

    .consult_patient_startup .mainpatientinside .statusContainer {
        margin-top: 1px;
    }

    .consult_patient_startup .mainpatientinside .statusContainer #feeinconsult {
        font-size: 13px;
    }

    .consult_patient_startup .mainpatientinside .statusContainer #dowithstatus {
        font-size: 13px;
    }
}




@media only screen and (min-width: 431px) and (max-width: 680px) {
    .consult_toptwo_tutton {
        width: 55%;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .consult_toptwo_tutton .activeProcess {
        width: 49%;
        height: 42px;
    }

    .consult_toptwo_tutton .inactiveProcess {
        background-color: white;
        width: 49%;
        height: 42px;
    }

    .consult_toptwo_tutton .processtext {
        font-size: 12px;
    }

    .consult_toptwo_tutton .inprocesstext {
        font-size: 12px;
    }

    .consult_patient_startup .skelton_appoint_div {
        width: 90%;
        margin: 10px auto;
    }

    .consult_patient_startup .skelton_appoint_div .sklnconsltheight {
        height: 180px;
        margin-bottom: 20px;
    }

    .consult_patient_startup .mainpatientinside {
        width: 90%;
        margin: 10px auto;
        padding: 12px;
    }

    .consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst {
        gap: 6px;
    }

    .consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst #userconsult {
        font-size: 14px;
    }

    .consult_patient_startup .mainpatientinside .firstrowContainer .headnameconst #datee2 {
        font-size: 11px;
    }

    .consult_patient_startup .mainpatientinside .firstrowContainer .borderedButton {
        padding: 6px;
        border: 2px solid #874DAF;
        font-size: 12px;
    }


    .consult_patient_startup .mainpatientinside .profileContainer {
        padding-top: 10px;
    }

    .consult_patient_startup .mainpatientinside .profileContainer .profileImageContainer {
        width: 55px;
        height: 55px;
        border: 1.5px solid #874DAF;
    }

    .consult_patient_startup .mainpatientinside .profileContainer .profileImageContainer .imagemainin {
        width: 50px;
        height: 50px;
    }

    .consult_patient_startup .mainpatientinside .profileContainer .columnContainer {
        margin-left: 7px;
        margin-top: 0px;
    }

    .consult_patient_startup .mainpatientinside .dateTimeContainer {
        margin-left: 45px;
        margin-top: 8px;
    }

    .consult_patient_startup .mainpatientinside .dateTimeContainer #Calendarmonthcon {
        font-size: 13px;
    }

    .consult_patient_startup .mainpatientinside .statusContainer {
        margin-top: 2px;
    }

    .consult_patient_startup .mainpatientinside .statusContainer #feeinconsult {
        font-size: 14px;
    }

    .consult_patient_startup .mainpatientinside .statusContainer #dowithstatus {
        font-size: 14px;
    }
}