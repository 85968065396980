.docsitnamfist {
    color: black;
    font-size: 15px;
    font-weight: bold;
}

.botnkatext {
    color: black;
    font-weight: bold;
}

.dockaimgkasect {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
}

.dockaimgkasect .pahalimg {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    border-color: #874DAF;
    justify-content: center;
    align-items: center;
}

.dockaimgkasect .pahalimg .oknowimgin {
    width: 55px;
    height: 55px;
    border-radius: 50px;
}

.dockaimgkasect .akriinimg {
    flex-direction: column;
    margin-left: 8px;
    margin-top: 4px;
}

.dockaimgkasect .akriinimg #nownmofusr {
    color: black;
    font-size: 15px;
    font-weight: bold;
}

.dockaimgkasect .akriinimg #nowadofusr {
    color: #39b4cc;
    font-size: 15px;
}

.opendatetimemix {
    color: #4b738c;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-left: 60px;
    margin-bottom: 10px;
}

.lasttwobtndoc {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}

.lasttwobtndoc .fistcallbutn {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;
    border-radius: 6px;
    padding: 5px 15px 5px 15px;
    background-color: lightgray;
}

.lasttwobtndoc .fistcallbutn .maincallbutn {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background-color: #f3f4f4;
    justify-content: center;
    align-items: center;
    border: none;
}

.lasttwobtndoc .fistcallbutn .textsizcalicn {
    color: #0751fc;
    font-size: 25px;
}

.lasttwobtndoc .secndchtbutn {
    display: flex;
    margin-left: 10px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 8px;
    padding: 6px;
    border: 2px solid #874DAF;
}

.lasttwobtndoc .secndchtbutn .iconsizcht {
    color: #874DAF;
    font-size: 16px;
}

.lasttwobtndoc .secndchtbutn .sizofchattxt {
    color: #874DAF;
    font-weight: bold;
    margin-left: 5px;
}






@media only screen and (min-width: 0px) and (max-width: 431px) {
    .docsitnamfist {
        font-size: 13px;
    }

    .botnkatext {
        font-size: 12px;
    }

    .dockaimgkasect {
        padding-top: 10px;
    }

    .dockaimgkasect .pahalimg {
        width: 45px;
        height: 45px;
    }

    .dockaimgkasect .pahalimg .oknowimgin {
        width: 45px;
        height: 45px;
    }

    .dockaimgkasect .akriinimg {
        margin-left: 5px;
        margin-top: 2px;
    }

    .dockaimgkasect .akriinimg #nownmofusr {
        font-size: 13px;
    }

    .dockaimgkasect .akriinimg #nowadofusr {
        font-size: 13px;
    }

    .opendatetimemix {
        gap: 2px;
        font-size: 13px;
        margin-left: 50px;
        margin-bottom: 3px;
    }

    .lasttwobtndoc {
        margin-top: 5px;
    }

    .lasttwobtndoc .fistcallbutn {
        margin-left: 7px;
        padding: 3px 10px 3px 10px;
    }

    .lasttwobtndoc .fistcallbutn .maincallbutn {
        width: 30px;
        height: 30px;
    }

    .lasttwobtndoc .fistcallbutn .textsizcalicn {
        font-size: 20px;
    }

    .lasttwobtndoc .secndchtbutn {
        margin-left: 7px;
        margin-top: 7px;
        padding: 4px;
        border: 1.5px solid #874DAF;
    }

    .lasttwobtndoc .secndchtbutn .iconsizcht {
        font-size: 14px;
    }

    .lasttwobtndoc .secndchtbutn .sizofchattxt {
        margin-left: 3px;
    }
}

@media only screen and (min-width: 431px) and (max-width: 680px) {
    .docsitnamfist {
        font-size: 14px;
    }

    .botnkatext {
        font-size: 13px;
    }

    .dockaimgkasect {
        padding-top: 15px;
    }

    .dockaimgkasect .pahalimg {
        width: 50px;
        height: 50px;
    }

    .dockaimgkasect .pahalimg .oknowimgin {
        width: 50px;
        height: 50px;
    }

    .dockaimgkasect .akriinimg {
        margin-left: 7px;
        margin-top: 3px;
    }

    .dockaimgkasect .akriinimg #nownmofusr {
        font-size: 14px;
    }

    .dockaimgkasect .akriinimg #nowadofusr {
        font-size: 14px;
    }

    .opendatetimemix {
        gap: 3px;
        font-size: 14px;
        margin-left: 55px;
        margin-bottom: 5px;
    }

    .lasttwobtndoc {
        margin-top: 7px;
    }

    .lasttwobtndoc .fistcallbutn {
        margin-left: 10px;
        padding: 4px 12px 4px 12px;
    }

    .lasttwobtndoc .fistcallbutn .maincallbutn {
        width: 35px;
        height: 35px;
    }

    .lasttwobtndoc .fistcallbutn .textsizcalicn {
        font-size: 22px;
    }

    .lasttwobtndoc .secndchtbutn {
        margin-left: 8px;
        margin-top: 8px;
        padding: 5px;
        border: 1.5px solid #874DAF;
    }

    .lasttwobtndoc .secndchtbutn .iconsizcht {
        font-size: 15px;
    }

    .lasttwobtndoc .secndchtbutn .sizofchattxt {
        margin-left: 4px;
    }
}