.roll_container {
    background-image: url('https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80');
    /* background-image: url('https://source.unsplash.com/800x600/?doctor'); */
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.roll_container .roll_card {
    background-color: #fff;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
}

.roll_container .roll_card h2 {
    text-align: center;
    margin-bottom: 30px;
}

/* 2 button styling */
.roll_container .roll_card .maininrolselect {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    margin-bottom: 20px;
}

.roll_container .roll_card .maininrolselect .patientimag {
    border-radius: 10px;
    width: 100px;
    height: 100px;
}

.roll_container .roll_card .maininrolselect .doctorimag {
    border-radius: 10px;
    width: 100px;
    height: 110px;
}

.roll_container .roll_card .maininrolselect .in_active_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: white;
    width: 46%;
    padding: 10px 0px 10px 0px;
    cursor: pointer;
}

.roll_container .roll_card .maininrolselect .in_active_button .in_active_text {
    color: gray;
    font-weight: bold;
    margin-top: 10px;
    font-size: 16px;
}

.roll_container .roll_card .maininrolselect .active_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #874DAF;
    width: 46%;
    padding: 10px 0px 10px 0px;
    box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.roll_container .roll_card .maininrolselect .active_button .active_text {
    color: white;
    font-weight: bold;
    margin-top: 10px;
    font-size: 16px;
}

/* ///////////////// */

.roll_container .roll_card h3 {
    text-align: center;
    margin-bottom: 80px;
    color: gray;
    font-size: 15px;
    font-weight: 400;
}

.roll_container .roll_card .butn {
    display: block;
    width: 100%;
    background-color: #874daf;
    border: 1px solid #874daf;
    color: #fff;
    padding: 12px;
    border-radius: 5px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.roll_container .roll_card .butn:hover {
    border: 1px solid #874daf;
    background-color: white;
    color: #874daf;
}




@media only screen and (min-width: 0px) and (max-width: 431px) {
    .roll_container .roll_card {
        padding: 30px;
        max-width: 300px;
    }

    .roll_container .roll_card h2 {
        font-size: 19px;
        margin-bottom: 20px;
    }

    /* 2 button styling */
    .roll_container .roll_card .maininrolselect {
        padding: 0 20px;
        margin-bottom: 15px;
    }

    .roll_container .roll_card .maininrolselect .patientimag {
        border-radius: 10px;
        width: 80px;
        height: 80px;
    }

    .roll_container .roll_card .maininrolselect .doctorimag {
        border-radius: 10px;
        width: 80px;
        height: 90px;
    }

    .roll_container .roll_card .maininrolselect .in_active_button {
        padding: 8px 0px 8px 0px;
    }

    .roll_container .roll_card .maininrolselect .active_button {
        padding: 8px 0px 8px 0px;
    }

    /* ///////////////// */
}

@media only screen and (min-width: 431px) and (max-width: 680px) {
    .roll_container .roll_card {
        padding: 40px;
        max-width: 350px;
    }

    .roll_container .roll_card h2 {
        font-size: 20px;
        margin-bottom: 25px;
    }

    /* 2 button styling */
    .roll_container .roll_card .maininrolselect {
        padding: 0 20px;
        margin-bottom: 15px;
    }

    .roll_container .roll_card .maininrolselect .patientimag {
        border-radius: 10px;
        width: 80px;
        height: 80px;
    }

    .roll_container .roll_card .maininrolselect .doctorimag {
        border-radius: 10px;
        width: 80px;
        height: 90px;
    }

    /* ///////////////// */
}

@media only screen and (min-width: 680px) and (max-width: 950px) {
    .roll_container .roll_card {
        padding: 50px;
        max-width: 370px;
    }

    .roll_container .roll_card h2 {
        font-size: 22px;
        margin-bottom: 30px;
    }

    /* 2 button styling */
    .roll_container .roll_card .maininrolselect {
        padding: 0 20px;
        margin-bottom: 20px;
    }

    .roll_container .roll_card .maininrolselect .patientimag {
        border-radius: 10px;
        width: 80px;
        height: 80px;
    }

    .roll_container .roll_card .maininrolselect .doctorimag {
        border-radius: 10px;
        width: 80px;
        height: 90px;
    }

    /* ///////////////// */
}