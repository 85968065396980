.Clinicnumberbground {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
}

.Clinicnumberbground .ClinicnumberContainer {
    width: 45%;
    border-radius: 12px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    margin: 15px 0px 15px 0px;
}


.ClinicnumberContainer .titleClinicnumberCloseBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 20px 0px 15px;
}

.ClinicnumberContainer .titleClinicnumberCloseBtn h3 {
    margin-left: 20px;
    font-weight: 500;
}

.ClinicnumberContainer .titleClinicnumberCloseBtn button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 5px;
}


.ClinicnumberContainer .resaon_inpu {
    width: 87%;
    align-self: center;
    background-color: lightgray;
    color: black;
    border-radius: 8px;
    padding: 10px;
    border: none;
    margin-top: 15px;
    display: flex;
    text-align: start;
    height: 80px;
    position: relative;
}



/* Input */
.label {
    color: black;
    font-size: 13px;
    padding: 0 15px;
    margin-top: 10px;
    font-weight: bold;
}

.phoneinput {
    margin-top: 10px;
    font-size: 14px;
    border: 1px solid #874DAF;
    align-self: center;
    height: 45px;
    width: 94%;
    border-radius: 8px;
    padding-left: 10px;
}

/* ////////// */



/* Payout Button */
.ClinicnumberContainer .Clinicnumber_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #874daf;
    padding: 12px 0px 12px 0px;
    width: 94%;
    align-self: center;
    margin-top: 30px;
    border-radius: 7px;
    margin-bottom: 20px;
}

.ClinicnumberContainer .Clinicnumber_btn {
    color: white;
}

/* ////////////////////////// */



@media only screen and (min-width: 0px) and (max-width: 431px) {
    .Clinicnumberbground .ClinicnumberContainer {
        width: 90%;
    }
}

@media only screen and (min-width: 431px) and (max-width: 680px) {
    .Clinicnumberbground .ClinicnumberContainer {
        width: 75%;
    }
}

@media only screen and (min-width: 680px) and (max-width: 950px) {
    .Clinicnumberbground .ClinicnumberContainer {
        width: 60%;
    }
}