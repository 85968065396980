.payout_appointground {
    background-color: rgba(2, 2, 2, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    position: fixed;
    height: 100%;
    z-index: 1;
}

.payout_appointground .payoutapointContainer {
    width: 35%;
    height: 510px;
    border-radius: 12px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
}

.payout_appointground .payoutapointContainer::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}

.payout_appointground .payoutapointContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.payout_appointground .payoutapointContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
}

.payoutapointContainer .titlepayoutapointCloseBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 15px 0px 10px;
}

.payoutapointContainer .titlepayoutapointCloseBtn h3 {
    margin-left: 15px;
    font-weight: 500;
}

.payoutapointContainer .titlepayoutapointCloseBtn button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 5px;
}

.payoutapointContainer #totaltextpay {
    font-size: 15px;
    margin-left: 15px;
    margin-top: 10px;
}

.payoutapointContainer #balanceinpayout {
    font-size: 16px;
    margin-left: 15px;
}

.payoutapointContainer #paydocumention {
    color: gray;
    font-weight: normal;
    font-size: 13px;
    margin-left: 15px;
    margin-top: 8px;
}


/* Input */
.payoutapointContainer .label {
    color: black;
    font-size: 13px;
    padding: 0 15px;
    margin-top: 8px;
    font-weight: bold;
}

.payoutapointContainer .phoneinput {
    margin-top: 6px;
    font-size: 13px;
    border: 1px solid #874DAF;
    align-self: center;
    height: 44px;
    width: 93%;
    border-radius: 8px;
    padding-left: 10px;
}

/* ////////// */



/* Payout Button */
.payoutapointContainer .payout_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #874daf;
    padding: 12px 0px 12px 0px;
    width: 93%;
    align-self: center;
    border-radius: 7px;
    position: absolute;
    bottom: 13px;
}

.payoutapointContainer .payout_btn {
    color: white;
}

/* ////////////////////////// */



@media only screen and (min-width: 0px) and (max-width: 431px) {
    .payout_appointground .payoutapointContainer {
        width: 90%;
    }

    .payoutapointContainer .titlepayoutapointCloseBtn {
        padding: 10px 12px 0px 8px;
    }

    .payoutapointContainer .titlepayoutapointCloseBtn h3 {
        margin-left: 10px;
        font-size: 17px;
    }

    .payoutapointContainer #totaltextpay {
        font-size: 14px;
        margin-left: 12px;
        margin-top: 8px;
    }

    .payoutapointContainer #balanceinpayout {
        font-size: 15px;
        margin-left: 12px;
    }

    .payoutapointContainer #paydocumention {
        font-size: 12px;
        margin-left: 12px;
        margin-top: 7px;
    }

    /* Input */
    .payoutapointContainer .label {
        font-size: 12px;
        padding: 0 12px;
        margin-top: 7px;
    }

    .payoutapointContainer .phoneinput {
        margin-top: 5px;
        font-size: 12px;
        height: 43px;
        width: 92%;
        padding-left: 8px;
    }

    /* ////////// */

    .payoutapointContainer .payout_btn {
        padding: 10px 0px 10px 0px;
        width: 92%;
    }

    .payoutapointContainer .payout_btn {
        font-size: 14px;
    }
}

@media only screen and (min-width: 431px) and (max-width: 680px) {
    .payout_appointground .payoutapointContainer {
        width: 65%;
    }

    .payoutapointContainer .titlepayoutapointCloseBtn {
        padding: 10px 12px 0px 8px;
    }

    .payoutapointContainer .titlepayoutapointCloseBtn h3 {
        margin-left: 10px;
        font-size: 17px;
    }

    .payoutapointContainer #totaltextpay {
        font-size: 14px;
        margin-left: 12px;
        margin-top: 8px;
    }

    .payoutapointContainer #balanceinpayout {
        font-size: 15px;
        margin-left: 12px;
    }

    .payoutapointContainer #paydocumention {
        font-size: 12px;
        margin-left: 12px;
        margin-top: 7px;
    }

    /* Input */
    .payoutapointContainer .label {
        font-size: 12px;
        padding: 0 12px;
        margin-top: 7px;
    }

    .payoutapointContainer .phoneinput {
        margin-top: 5px;
        font-size: 12px;
        height: 43px;
        width: 92%;
        padding-left: 8px;
    }

    /* ////////// */

    .payoutapointContainer .payout_btn {
        padding: 10px 0px 10px 0px;
        width: 92%;
    }

    .payoutapointContainer .payout_btn {
        font-size: 14px;
    }
}

@media only screen and (min-width: 680px) and (max-width: 950px) {
    .payout_appointground .payoutapointContainer {
        width: 50%;
    }
}