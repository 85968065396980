.navbar {
  background: #874daf;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  z-index: 12;
}

.navbar_logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 63vw;
  justify-content: end;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links.active {
  color: white;
  background-color: #9b77b5;
  border-radius: 4px;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #9b77b5;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.menu_icon {
  display: none;
}

.fa-times {
  display: none;
}


.lgotorsetngbtn {
  display: flex;
}

.butnidshortlgot {
  display: none;
}

@media screen and (max-width: 680px) {
  .navbar {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    font-size: 0.9rem;
  }

  .shortyscrn {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0px 13px 0px 13px;
  }

  .navbar_logo {
    font-size: 1.3rem;
  }

  .menu_icon {
    display: initial;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .fa-times {
    display: initial;
    color: #fff;
    font-size: 1.8rem;
    align-self: flex-end;
    margin: 25px 23px 15px 0px;
  }

  .fa-bars {
    font-size: 1.8rem;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    position: absolute;
    padding-bottom: 75px;
    top: 0px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 11;
    overflow: auto;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 11;
  }

  .nav-menu::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }

  .nav-menu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  .nav-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
  }

  .nav-item {
    justify-content: center;
    height: 55px;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-links.active {
    width: 95%;
    height: 55px;
    color: white;
    background-color: #9b77b5;
    border-radius: 4px;
    text-decoration: none;
  }

  .nav-links:hover {
    width: 95%;
    height: 100%;
    color: white;
    background-color: #9b77b5;
    border-radius: 4px;
    text-decoration: none;
  }

  .lgotorsetngbtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-85%, 120%);
    cursor: pointer;
  }

  .lgotorsetngbtn .butnidlgot {
    display: none;
  }

  .butnidshortlgot {
    display: initial;
  }
}

@media only screen and (min-width: 680px) and (max-width: 900px) {
  .navbar {
    height: 70px;
    font-size: 0.9rem;
  }

  .navbar_logo {
    font-size: 0rem;
  }

  .nav-menu {
    grid-gap: 3px;
    width: 65vw;
    justify-content: center;
  }

  .nav-links {
    padding: 0.3rem 0.6rem;
  }

  .nav-links.active {
    padding: 0.3rem 0.6rem;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1050px) {
  .navbar {
    height: 80px;
    font-size: 0.9rem;
  }

  .navbar_logo {
    font-size: 1.3rem;
  }

  .nav-menu {
    grid-gap: 5px;
    width: 60vw;
  }

  .nav-links {
    padding: 0.4rem 0.9rem;
  }

  .nav-links.active {
    padding: 0.4rem 0.9rem;
  }
}