.modal_paymentBackground {
  background-color: rgba(2, 2, 2, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  position: fixed;
  height: 100%;
}

.modal_paymentBackground .modalpaymentContainer {
  width: 35%;
  height: 510px;
  border-radius: 12px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

.modal_paymentBackground .modalpaymentContainer::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

.modal_paymentBackground .modalpaymentContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.modal_paymentBackground .modalpaymentContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #874daf;
}


.modal_paymentBackground .modalpaymentContainer .headerpaymentCloseBtn {
  display: flex;
  flex-direction: row;
  padding: 20px 25px 0px 25px;
  align-items: center;
}

.modal_paymentBackground .modalpaymentContainer .headerpaymentCloseBtn h3 {
  color: black;
  font-size: 20px;
  margin-top: -3px;
  margin-left: 15px;
}

.modal_paymentBackground .modalpaymentContainer .headerpaymentCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 15px;
}

.modal_paymentBackground .modalpaymentContainer .headerpaymentCloseBtn button .iconnn {
  font-size: 22px;
  color: black;
}





/* ............................. */
#payment_form {
  border: #F6F9FC solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  /* box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%); */
}

#payment_form #payment_element input,
#payment_form #payment_element select {
  display: block;
  font-size: 1.1em;
  width: 100%;
  margin-bottom: 10px;
}

#payment_form #payment_element label {
  display: block;
}

#payment_form #payment_element a {
  color: var(--accent-color);
  font-weight: 900;
}

#payment_form #payment_element small {
  font-size: .6em;
}

#payment_form #payment_element fieldset,
#payment_form #payment_element input,
#payment_form #payment_element select {
  border: 1px solid #efefef;
}

#payment_form #messages {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New'; */
  display: none;
  /* hide initially, then show once the first message arrives */
  background-color: #0A253C;
  color: #00D924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size: 0.7em;
}

/* ///////////////////////////////////// */






#payment_form .payments_button {
  align-items: center;
  justify-content: center;
  background-color: #874daf;
  padding: 12px 0px 12px 0px;
  width: 90%;
  align-self: center;
  border-radius: 7px;
  position: absolute;
  bottom: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 0;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
  color: white;
  font-weight: 600;
}

#payment_form .payments_button:hover {
  filter: contrast(115%);
}

#payment_form .payments_button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}

#payment_form .payments_button:disabled {
  opacity: 0.5;
  cursor: none;
}




@media only screen and (min-width: 0px) and (max-width: 431px) {
  .modal_paymentBackground .modalpaymentContainer {
    width: 90%;
  }

  .modal_paymentBackground .modalpaymentContainer .headerpaymentCloseBtn {
    padding: 15px 18px 0px 18px;
  }

  .modal_paymentBackground .modalpaymentContainer .headerpaymentCloseBtn h3 {
    font-size: 18px;
    margin-top: -4px;
    margin-left: 10px;
  }

  .modal_paymentBackground .modalpaymentContainer .headerpaymentCloseBtn button .iconnn {
    font-size: 18px;
  }

  #payment_form .payments_button {
    padding: 10px 0px 10px 0px;
    font-size: 12px
  }
}



@media only screen and (min-width: 431px) and (max-width: 680px) {
  .modal_paymentBackground .modalpaymentContainer {
    width: 65%;
  }

  .modal_paymentBackground .modalpaymentContainer .headerpaymentCloseBtn {
    padding: 15px 18px 0px 18px;
  }

  .modal_paymentBackground .modalpaymentContainer .headerpaymentCloseBtn h3 {
    font-size: 18px;
    margin-top: -4px;
    margin-left: 10px;
  }

  .modal_paymentBackground .modalpaymentContainer .headerpaymentCloseBtn button .iconnn {
    font-size: 18px;
  }
}



@media only screen and (min-width: 680px) and (max-width: 950px) {
  .modal_paymentBackground .modalpaymentContainer {
    width: 50%;
  }
}