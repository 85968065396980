.update_schedualBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
}

.update_schedualContainer {
    width: 60%;
    border-radius: 12px;
    background-color: #eaeef3;
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin: 15px 0px 15px 0px;
}



/* Online or Offline */
.update_schedualContainer .updatebuttonContainer {
    width: 85%;
    align-self: center;
    margin: 10px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.update_schedualContainer .updatebuttonContainer .activeButton {
    background-color: #874DAF;
    color: white;
    padding: 12px 45px;
    border-radius: 5px;
    border: 3px solid #874DAF;
}

.update_schedualContainer .updatebuttonContainer .inactiveButton {
    background-color: white;
    color: #874DAF;
    padding: 12px 45px;
    border-radius: 5px;
    border: 1.5px solid #874DAF;
}

/* ///////////////////// */


/* 7 DAY Style */
.update_schedualContainer .maindivofall7day {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.update_schedualContainer .maindivofall7day .view1 {
    border: none;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: white;
}

.update_schedualContainer .maindivofall7day .view {
    border: none;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #874DAF;
}

.update_schedualContainer .maindivofall7day .view1 .text1 {
    color: gray;
}

.update_schedualContainer .maindivofall7day .view .text {
    color: white;
}

/* Add Button */
.donebuttoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 7px;
}

.customButton {
    width: calc(100% - 22px);
    /* width / 1.1 */
    background-color: #874DAF;
    align-self: center;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 10px 0;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: none;
    box-shadow: 0 2px 3.84px 0 rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s ease-in-out;
}

.customButton:hover {
    background-color: #6c3d8f;
}

/* ////////////////////// */





/* /////////////// */
.timedate_Dialog {
    width: 100%;
    height: 100%;
}

.timedate_Dialog .timeowninside {
    padding: 20px;
    margin: 5px 10px 0px 10px;
}

.timedate_Dialog .timedate_Ok_nd_Close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid lightgray;
    margin-top: 10px;
}

.timedate_Dialog .timedate_Ok_nd_Close .freeapoint_Ok {
    width: 100%;
    color: #874daf;
    font-weight: bold;
}

/* ....................... */





@media only screen and (min-width: 0px) and (max-width: 431px) {
    .update_schedualContainer {
        width: 95%;
        padding: 10px;
        margin: 5px 0px 5px 0px;
    }

    .update_schedualContainer p {
        font-size: 12px;
    }

    /* Online or Offline */
    .update_schedualContainer .updatebuttonContainer {
        width: 100%;
        margin-top: 8px;
        gap: 6px;
    }

    .update_schedualContainer .updatebuttonContainer .activeButton {
        padding: 8px 30px;
        border: 2px solid #874DAF;
    }

    .update_schedualContainer .updatebuttonContainer .inactiveButton {
        padding: 8px 30px;
        border: 1px solid #874DAF;
    }

    /* ///////////////////// */
}



@media only screen and (min-width: 431px) and (max-width: 680px) {
    .update_schedualContainer {
        width: 85%;
        padding: 15px;
        margin: 8px 0px 8px 0px;
    }

    .update_schedualContainer p {
        font-size: 13px;
    }


    /* Online or Offline */
    .update_schedualContainer .updatebuttonContainer {
        width: 100%;
        margin-top: 8px;
        gap: 8px;
    }

    .update_schedualContainer .updatebuttonContainer .activeButton {
        padding: 10px 40px;
        border: 2.5px solid #874DAF;
    }

    .update_schedualContainer .updatebuttonContainer .inactiveButton {
        padding: 10px 40px;
        border: 1.5px solid #874DAF;
    }

    /* ///////////////////// */
}



@media only screen and (min-width: 680px) and (max-width: 950px) {
    .update_schedualContainer {
        width: 70%;
        padding: 18px;
        margin: 10px 0px 10px 0px;
    }

    .update_schedualContainer p {
        font-size: 15px;
    }
}