:root {
  --primary: #1888ff;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: var(--primary);
}

.btn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}


@media screen and (max-width: 680px) {
  .btn {
    display: block;
    position: fixed;
    bottom: 0;
    align-self: center;
    cursor: pointer;

    width: 100%;
    height: 60px;
    padding: 6px 12px;
    font-size: 13px;
  }
}

@media only screen and (min-width: 680px) and (max-width: 900px) {
  .btn {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1050px) {
  .btn {
    padding: 7px 18px;
    font-size: 16px;
  }
}