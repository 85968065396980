.Doctor_urgentsideBackground {
    background-color: rgba(2, 2, 2, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    position: fixed;
    height: 100%;
}

.Doctor_urgentsideBackground .doctorurgentsideContainer {
    width: 40%;
    height: 510px;
    border-radius: 12px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    padding: 20px 10px 20px 10px;
    overflow: auto;
}

.Doctor_urgentsideBackground .doctorurgentsideContainer::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.Doctor_urgentsideBackground .doctorurgentsideContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.Doctor_urgentsideBackground .doctorurgentsideContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
}

.doctorurgentsideContainer .titledoctorurgentsideCloseBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.doctorurgentsideContainer .titledoctorurgentsideCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}

.doctorurgentsideContainer .titledoctorurgentsideCloseBtn h3 {
    margin-right: 15px;
}




/* First Card */
.your-container-style {
    width: 93%;
    margin-top: 10px;
    margin-bottom: 2px;
    align-self: center;
    background-color: white;
    padding: 18px;
    border-radius: 3px;
    box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
}

.row-style {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.column-style {
    width: 30%;
    justify-content: center;
    margin-left: 20px;
}

.label-style {
    color: black;
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: bold;
}

.value-style {
    color: black;
    font-size: 14px;
    margin-bottom: 12px;
}

.status-style {
    color: #15ca8b;
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: 400;
}

.payment-style {
    color: #15ca8b;
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: 400;
}


/* /////////////////////// */









@media only screen and (min-width: 0px) and (max-width: 431px) {
    .Doctor_urgentsideBackground .doctorurgentsideContainer {
        width: 95%;
        padding: 20px 10px 10px 10px;
    }

    .doctorurgentsideContainer .titledoctorurgentsideCloseBtn h3 {
        font-size: 18px;
        margin-right: 25px;
    }

    .your-container-style {
        width: 95%;
        padding: 10px;
    }

    .your-container-style #datestyleid {
        font-size: 10px;
    }

    .label-style {
        font-size: 11px;
        margin-bottom: 12px;
    }

    .value-style {
        font-size: 11px;
    }

    .status-style {
        font-size: 11px;
        margin-bottom: 12px;
    }

    .payment-style {
        font-size: 11px;
        margin-bottom: 12px;
    }
}

@media only screen and (min-width: 431px) and (max-width: 680px) {
    .Doctor_urgentsideBackground .doctorurgentsideContainer {
        width: 75%;
        padding: 20px 10px 10px 10px;
    }

    .doctorurgentsideContainer .titledoctorurgentsideCloseBtn h3 {
        font-size: 18px;
        margin-right: 25px;
    }

    .your-container-style {
        width: 95%;
        padding: 8px;
    }

    .your-container-style #datestyleid {
        font-size: 11px;
    }

    .label-style {
        font-size: 12px;
        margin-bottom: 12px;
    }

    .value-style {
        font-size: 12px;
    }

    .status-style {
        font-size: 12px;
        margin-bottom: 12px;
    }

    .payment-style {
        font-size: 12px;
        margin-bottom: 12px;
    }
}




@media only screen and (min-width: 680px) and (max-width: 950px) {
    .Doctor_urgentsideBackground .doctorurgentsideContainer {
        width: 55%;
        padding: 20px 10px 10px 10px;
    }

    .doctorurgentsideContainer .titledoctorurgentsideCloseBtn h3 {
        font-size: 18px;
        margin-right: 25px;
    }

    .your-container-style {
        width: 95%;
        padding: 10px;
    }

    .your-container-style #datestyleid {
        font-size: 12px;
    }

    .label-style {
        font-size: 13px;
        margin-bottom: 12px;
    }

    .value-style {
        font-size: 13px;
    }

    .status-style {
        font-size: 13px;
        margin-bottom: 12px;
    }

    .payment-style {
        font-size: 13px;
        margin-bottom: 12px;
    }
}