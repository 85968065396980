.modalBackground {
  background-color: rgba(2, 2, 2, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  position: fixed;
  height: 100%;
}

.modalContainer {
  width: 60%;
  height: 510px;
  border-radius: 12px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 25px;
  overflow: auto;
}

.modalContainer::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

.modalContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.modalContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #874daf;
}

.modalContainer .titleCloseBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalContainer .titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .title {
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 10px;
}

/* 7 Days Style */
.modalContainer .daysContainer {
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.modalContainer .daysContainer .dayButton {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalContainer .daysContainer .dayButton .dayOfWeekText {
  color: gray;
  font-size: 14px;
}

.modalContainer .daysContainer .selectedDayButton {
  background-color: #874DAF;
}

.modalContainer .daysContainer .selectedDayButton .selecteddayOfWeekText {
  color: white;
  font-size: 14px;
}

/* ////////////////// */


/* Slot mian view */
.modalContainer .all_time_slot {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.modalContainer .slots_list {
  display: flex;
  flex-wrap: wrap;
}

.modalContainer .slots_list .slotbutton {
  padding: 9px 5px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-color: lightgray;
  border-width: 0.5px;
  border-radius: 6px;
  margin: 7px 3px;
}

.modalContainer .slots_list .slotbutton .slotText {
  color: gray;
}

.modalContainer .slots_list .selectedSlotButton {
  padding: 9px 5px;
  justify-content: center;
  align-items: center;
  background-color: #874DAF;
  border-color: #874DAF;
  border-width: 0.5px;
  border-radius: 6px;
  margin: 7px 3px;
}

.modalContainer .slots_list .selectedSlotButton .selectedSlotText {
  color: white;
}

.modalContainer .slots_list .bookedSlotButton {
  padding: 9px 5px;
  justify-content: center;
  align-items: center;
  background-color: #eae9ee;
  border-color: #eae9ee;
  border-width: 0.5px;
  border-radius: 6px;
  margin: 7px 3px;
}

.modalContainer .slots_list .bookedSlotButton .bookedSlotText {
  color: gray;
}

.modalContainer .slots_list .pastSlotButton {
  padding: 9px 5px;
  justify-content: center;
  align-items: center;
  background-color: #eae9ee;
  border-color: #eae9ee;
  border-width: 0.5px;
  border-radius: 6px;
  margin: 7px 3px;
}

.modalContainer .slots_list .pastSlotButton .pastSlotText {
  color: gray;
}

/* ...................... */




/* Modal Input Style */
.modalContainer .patient_first_name {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.modalContainer .patient_first_name .update_width_both_name_email {
  width: 94%;
  display: flex;
  justify-content: space-between;
}

.modalContainer .patient_first_name .update_width_both_name_email .update_width_name {
  width: 49%;
}

.modalContainer .patient_first_name .update_width_both_name_email .update_width_name #name {
  width: 100%;
  border: 0.1px solid gray;
  padding: 12px;
  border-radius: 8px;
}

.modalContainer .patient_first_name .update_width_both_name_email .update_width_name #email {
  width: 100%;
  border: 0.1px solid gray;
  padding: 12px;
  border-radius: 8px;
}

/* ............... */



.modalContainer .book_btn {
  display: flex;
  flex-direction: row;
  align-self: center;
  background-color: #874daf;
  padding: 13px 0px 13px 0px;
  margin-top: 20px;
  border-radius: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  width: 35%;
  transition: 0.4s ease-in-out;
}

.modalContainer .book_btn:hover {
  border: 1px solid #874daf;
  background-color: white;
  color: #874daf;
}



/* ................. */
.modalBackground .spinner_main_loader {
  width: 100%;
  height: 100%;
  background-color: rgba(2, 2, 2, 0.8);
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.modalBackground .spinner_main_loader .spinner_inside {
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 6px;
  box-shadow: 0 5px 15px rgba(32, 32, 32, .3);
}

/* .............. */




@media only screen and (min-width: 0px) and (max-width: 431px) {
  .modalContainer {
    width: 95%;
    padding: 10px;
  }

  .modalContainer .titleCloseBtn h2 {
    font-size: 16px;
  }

  .modalContainer .titleCloseBtn button {
    font-size: 16px;
  }

  .modalContainer .title {
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .modalContainer .title h3 {
    font-size: 14px;
  }

  /* 7 Days Style */
  .modalContainer .daysContainer {
    padding-bottom: 3px;
  }

  .modalContainer .daysContainer .dayButton {
    padding: 5px;
  }

  .modalContainer .daysContainer .dayButton .dayOfWeekText {
    font-size: 11px;
  }

  .modalContainer .daysContainer .selectedDayButton .selecteddayOfWeekText {
    font-size: 11px;
  }

  /* ////////////////// */


  /* Slot mian view */
  .modalContainer .slots_list .slotbutton {
    padding: 7px 3px;
    margin: 5px 1px;
  }

  .modalContainer .slots_list .slotbutton .slotText {
    font-size: 14px;
  }

  .modalContainer .slots_list .selectedSlotButton {
    padding: 7px 3px;
    margin: 5px 1px;
  }

  .modalContainer .slots_list .selectedSlotButton .selectedSlotText {
    font-size: 14px;
  }

  .modalContainer .slots_list .bookedSlotButton {
    padding: 7px 3px;
    margin: 5px 1px;
  }

  .modalContainer .slots_list .bookedSlotButton .bookedSlotText {
    font-size: 14px;
  }

  .modalContainer .slots_list .pastSlotButton {
    padding: 7px 3px;
    margin: 5px 1px;
  }

  .modalContainer .slots_list .pastSlotButton .pastSlotText {
    font-size: 14px;
  }

  .modalContainer #noslotavalable {
    font-size: 14px;
  }

  /* ...................... */

  .modalContainer .book_btn {
    padding: 12px 0px 12px 0px;
    margin-top: 20px;
    width: 65%;
    font-size: 14px;
  }
}





@media only screen and (min-width: 431px) and (max-width: 680px) {
  .modalContainer {
    width: 85%;
    padding: 15px;
  }

  .modalContainer .titleCloseBtn h2 {
    font-size: 18px;
  }

  .modalContainer .titleCloseBtn button {
    font-size: 18px;
  }

  .modalContainer .title {
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .modalContainer .title h3 {
    font-size: 15px;
  }

  /* 7 Days Style */
  .modalContainer .daysContainer {
    padding-bottom: 5px;
  }

  .modalContainer .daysContainer .dayButton {
    padding: 7px;
  }

  .modalContainer .daysContainer .dayButton .dayOfWeekText {
    font-size: 12px;
  }

  .modalContainer .daysContainer .selectedDayButton .selecteddayOfWeekText {
    font-size: 12px;
  }

  /* ////////////////// */


  /* Slot mian view */
  .modalContainer .slots_list .slotbutton {
    padding: 8px 4px;
    margin: 6px 2px;
  }

  .modalContainer .slots_list .slotbutton .slotText {
    font-size: 15px;
  }

  .modalContainer .slots_list .selectedSlotButton {
    padding: 8px 4px;
    margin: 6px 2px;
  }

  .modalContainer .slots_list .selectedSlotButton .selectedSlotText {
    font-size: 15px;
  }

  .modalContainer .slots_list .bookedSlotButton {
    padding: 8px 4px;
    margin: 6px 2px;
  }

  .modalContainer .slots_list .bookedSlotButton .bookedSlotText {
    font-size: 15px;
  }

  .modalContainer .slots_list .pastSlotButton {
    padding: 8px 4px;
    margin: 6px 2px;
  }

  .modalContainer .slots_list .pastSlotButton .pastSlotText {
    font-size: 15px;
  }

  .modalContainer #noslotavalable {
    font-size: 15px;
  }

  /* ...................... */

  .modalContainer .book_btn {
    padding: 12px 0px 12px 0px;
    margin-top: 20px;
    width: 55%;
  }
}

@media only screen and (min-width: 680px) and (max-width: 950px) {
  .modalContainer {
    width: 70%;
    padding: 20px;
  }

  .modalContainer .titleCloseBtn h2 {
    font-size: 20px;
  }

  .modalContainer .titleCloseBtn button {
    font-size: 20px;
  }

  .modalContainer .title {
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .modalContainer .title h3 {
    font-size: 17px;
  }

  .modalContainer .book_btn {
    padding: 12px 0px 12px 0px;
    margin-top: 20px;
    width: 45%;
  }
}