.Doctor_sideBackground {
    background-color: rgba(2, 2, 2, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    position: fixed;
    height: 100%;
}

.Doctor_sideBackground .doctorsideContainer {
    width: 40%;
    height: 510px;
    border-radius: 12px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: auto;
}

.Doctor_sideBackground .doctorsideContainer::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.Doctor_sideBackground .doctorsideContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.Doctor_sideBackground .doctorsideContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
}

.doctorsideContainer .titledoctorsideCloseBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.doctorsideContainer .titledoctorsideCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}

.doctorsideContainer .titledoctorsideCloseBtn h3 {
    margin-right: 15px;
}



/* First Card */
.profile_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 15px;
    margin-top: 10px;
}

.profile_image_container {
    width: 60px;
    height: 60px;
    border-radius: 15px;
    border: 3px solid #874DAF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile_image {
    width: 55px;
    height: 55px;
    border-radius: 12px;
}

.profile_info {
    margin-left: 10px;
}

.user_name {
    color: black;
    font-weight: bold;
}

.gender {
    color: gray;
}


.info_container {
    padding: 0 15px;
    margin-top: 10px;
}

.info_row {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.icon {
    color: gray;
    font-size: 15px;
}

.info-text {
    color: black;
    margin-left: 10px;
}

/* /////////////// */



/* Date and Time */
.button_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    width: 92%;
    align-self: center;
    margin: 10 auto;
}

.button_container .chat_button {
    display: flex;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 15px 12px;
    border: 3px solid #874DAF;
    width: 50%;
    color: #874DAF;
    gap: 5px;
    font-size: 17px;
    font-weight: bold;
}

.button_container .video_main_div {
    display: flex;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 8px;
    width: 40%;
    border: none;
    background-color: #874DAF;
}

.button_container .video_main_div .video_button {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background-color: #f3f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.divider {
    border-width: 0.5px;
    border-color: lightgray;
    margin-top: 15px;
}

.date-time-container {
    padding: 0 15px;
    margin-top: 10px;
}

.gray-text {
    color: gray;
}

.bold-text {
    color: black;
    font-weight: bold;
}

/* //////////////// */



/* Cancel or Reschedual two button */
.appointment-buttons {
    width: 92%;
    margin: 0 auto;
    display: flex;
    align-self: center;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 10px 0;
    margin-top: 10px;
    margin-bottom: 5px;
    border-radius: 8px;
    border-top-width: 1px;
    border-bottom-width: 1px;

    /* Shadow properties */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.cancel-button,
.reschedule-button {
    width: 48%;
    padding: 10px 0;
    border: none;
    background-color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.cancel-button {
    color: red;
}

.reschedule-button {
    color: black;
}

/* /////////////////// */



/* Payment Methode */
.payment-info {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #effbfb;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 0.5px solid lightgray;
}

.payment-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.payment-title {
    color: black;
    font-weight: bold;
}

.payment-status {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.antdesign-icon {
    color: #874DAF;
    font-size: 17px;
    font-weight: bold;
}

.status-text {
    color: #874DAF;
    font-weight: bold;
    margin-left: 10px;
}

/* ...................... */



/* Issue show card */
.your-component-container {
    width: 100%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.skalton-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    height: 100%;
}

.no-data-container {
    display: flex;
    align-items: center;
    margin-top: 50px;
    justify-content: center;
}

.data-container {
    width: 100%;
    box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
}

.data-container .imageshowindetail {
    width: 98%;
    height: 170px;
    border-radius: 5px;
    align-self: center;
}

.divider {
    width: 100%;
    border-bottom: 1px solid lightgray;
    margin-top: 10px;
    margin-bottom: 5px;
}

.detail-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
}

.detail-row p {
    margin-left: 10px;
}

.detail-row p:first-child {
    margin-left: 0;
    font-weight: bold;
}


.prescription-container {
    width: 100%;
    align-self: center;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    padding: 10px 20px;
    background-color: white;
    margin-bottom: 10px;
}

.prescription-title {
    color: #874DAF;
    font-weight: bold;
    font-size: 14px;
}

.divider {
    width: 100%;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid lightgray;
}

.prescription-text {
    color: black;
    font-size: 12px;
    margin-top: 10px;
}

/* ///////////////////////////// */




/* Prescription for complete */
.physician-solution {
    color: black;
    margin-top: 10px;
}

.text-input {
    width: 100%;
    align-self: center;
    background-color: white;
    height: 120px;
    border-radius: 10px;
    padding: 10px;
    color: black;
    margin-top: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 15px;
}


.button-android {
    width: 100%;
    background-color: #874DAF;
    border-radius: 8px;
    align-self: center;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border: none;
    margin: 15px 0px 0px 0px;
}

.download-button {
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    background-color: #874DAF;
    padding: 15px;
    border: none;
    border-radius: 10px;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}

/* ..................... */





@media only screen and (min-width: 0px) and (max-width: 431px) {
    .Doctor_sideBackground .doctorsideContainer {
        width: 95%;
        padding: 20px 10px 10px 10px;
    }

    .doctorsideContainer .titledoctorsideCloseBtn button {
        font-size: 14px;
    }

    .doctorsideContainer .titledoctorsideCloseBtn h3 {
        margin-right: 20px;
    }

    .profile_container {
        padding: 0 8px;
    }

    .info_container {
        padding: 0 8px;
    }

    .button_container {
        width: 93%;
    }

    .button_container .chat_button {
        padding: 8px 6px;
        border: 3px solid #874DAF;
        width: 55%;
        gap: 3px;
        font-size: 12px;
    }

    .button_container .video_main_div {
        padding: 4px;
        width: 35%;
    }

    .button_container .video_main_div .video_button {
        width: 34px;
        height: 34px;
    }

    .date-time-container {
        padding: 0 8px;
    }

    .appointment-buttons {
        width: 93%;
        padding: 8px 0;
    }

    .payment-info {
        padding: 10px;
    }

    .payment-title {
        font-size: 14px;
    }

    .antdesign-icon {
        font-size: 14px;
    }

    .status-text {
        font-size: 14px;
        margin-left: 8px;
    }

    .your-component-container {
        padding: 10px;
    }
}



@media only screen and (min-width: 431px) and (max-width: 680px) {
    .Doctor_sideBackground .doctorsideContainer {
        width: 75%;
        padding: 20px 10px 10px 10px;
    }

    .doctorsideContainer .titledoctorsideCloseBtn button {
        font-size: 16px;
    }

    .doctorsideContainer .titledoctorsideCloseBtn h3 {
        margin-right: 20px;
    }

    .profile_container {
        padding: 0 10px;
    }

    .info_container {
        padding: 0 10px;
    }

    .button_container {
        margin-top: 15px;
        width: 92%;
        margin: 10 auto;
    }

    .button_container .chat_button {
        padding: 10px 8px;
        border: 3px solid #874DAF;
        width: 55%;
        gap: 3px;
        font-size: 13px;
    }

    .button_container .video_main_div {
        padding: 5px;
        width: 35%;
    }

    .button_container .video_main_div .video_button {
        width: 36px;
        height: 36px;
    }

    .date-time-container {
        padding: 0 10px;
    }
}



@media only screen and (min-width: 680px) and (max-width: 950px) {
    .Doctor_sideBackground .doctorsideContainer {
        width: 55%;
        padding: 20px 10px 10px 10px;
    }

    .doctorsideContainer .titledoctorsideCloseBtn button {
        font-size: 20px;
    }

    .doctorsideContainer .titledoctorsideCloseBtn h3 {
        margin-right: 20px;
    }


    .button_container .chat_button {
        padding: 13px 10px;
        border: 3px solid #874DAF;
        width: 52%;
        gap: 3px;
        font-size: 15px;
    }

    .button_container .video_main_div {
        padding: 6px;
        width: 38%;
    }

    .button_container .video_main_div .video_button {
        width: 40px;
        height: 40px;
    }
}