.type_selectBackground {
  background-color: rgba(2, 2, 2, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  position: fixed;
  height: 100%;
  z-index: 3;
}

.type_selectBackground .typeselectContainer {
  width: 35%;
  height: 500px;
  border-radius: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
}


.typeselectContainer .titletypeCloseBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 25px 0px 25px;
}

.typeselectContainer .titletypeCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}





/* New Header Section */
.typeselectContainer .headr_typeselect {
  width: 100%;
  /* background-color: pink; */
  padding: 0px 0px 40px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.typeselectContainer .headr_typeselect .main_div_inheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
}

.typeselectContainer .headr_typeselect .main_div_inheader .video_consultation {
  background-color: #e8f6f9;
  width: 43%;
  height: 180px;
  border-radius: 10px;
  padding-top: 12px;
  padding-left: 12px;
  /* box-shadow: 0px 2px 10px #c5c5c5; */
}

.typeselectContainer .headr_typeselect .main_div_inheader .video_consultation h4 {
  color: #384e78;
  font-size: 13px;
}

.typeselectContainer .headr_typeselect .main_div_inheader .video_consultation h5 {
  color: gray;
  font-weight: 600;
  font-size: 11px;
}

.typeselectContainer .headr_typeselect .main_div_inheader .video_consultation .image_consult {
  height: 100%;
  width: 100%;
  display: flex;
  align-self: center;
}

.typeselectContainer .headr_typeselect .main_div_inheader .onlyurgentsingle {
  background-color: #fbe0d0;
  width: 52%;
  height: 180px;
  border-radius: 10px;
  padding-top: 12px;
  padding-left: 12px;
}

.typeselectContainer .headr_typeselect .main_div_inheader .onlyurgentsingle h4 {
  color: #384e78;
  font-size: 13px;
}

.typeselectContainer .headr_typeselect .main_div_inheader .onlyurgentsingle h5 {
  color: gray;
  font-weight: 600;
  font-size: 11px;
}

.typeselectContainer .headr_typeselect .main_div_inheader .onlyurgentsingle .image_consult {
  height: 100%;
  width: 60%;
  object-fit: contain;
  display: flex;
  align-self: flex-end;
}


.typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn {
  display: flex;
  flex-direction: column;
  width: 55%;
  height: 180px;
  justify-content: space-between;
}

.typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .book_appointm_btn {
  width: 100%;
  height: 47%;
  background-color: #fbe0d0;
  border-radius: 10px;
  /* box-shadow: 0px 2px 10px #c5c5c5; */
}

.typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .book_appointm_btn h4 {
  color: #384e78;
  font-size: 12px;
}

.typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .book_appointm_btn h5 {
  color: gray;
  font-weight: 600;
  font-size: 10px;
}

.typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .book_appointm_btn .image_book {
  width: 40%;
  height: 100%;
}


.typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .free_consult_btn {
  width: 100%;
  height: 47%;
  background-color: #fef5de;
  border-radius: 10px;
  /* box-shadow: 0px 2px 10px #c5c5c5; */
}

.typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .free_consult_btn h4 {
  color: #384e78;
  font-size: 12px;
}

.typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .free_consult_btn h5 {
  color: gray;
  font-weight: 600;
  font-size: 10px;
}

.typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .free_consult_btn .image_free {
  width: 30%;
  height: 100%;
  object-fit: contain;
}

/* //////////////////////////// */



@media only screen and (min-width: 0px) and (max-width: 431px) {
  .type_selectBackground .typeselectContainer {
    width: 90%;
  }

  .typeselectContainer .titletypeCloseBtn {
    padding: 10px 15px 0px 15px;
  }

  .typeselectContainer .titletypeCloseBtn h2 {
    font-size: 18px;
  }

  .typeselectContainer .titletypeCloseBtn button {
    font-size: 18px;
  }

  .typeselectContainer .headr_typeselect h4 {
    font-size: 13px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .video_consultation {
    width: 46%;
    padding-top: 8px;
    padding-left: 8px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .video_consultation h4 {
    font-size: 10px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .video_consultation h5 {
    font-size: 8px;
    font-weight: 400;
  }


  .typeselectContainer .headr_typeselect .main_div_inheader .onlyurgentsingle {
    width: 48%;
    padding-top: 8px;
    padding-left: 8px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .onlyurgentsingle h4 {
    font-size: 10px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .onlyurgentsingle h5 {
    font-size: 8px;
    font-weight: 400;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn {
    width: 53%;
    height: 180px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .book_appointm_btn h4 {
    font-size: 9px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .book_appointm_btn h5 {
    font-size: 7px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .free_consult_btn h4 {
    font-size: 9px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .free_consult_btn h5 {
    font-size: 7px;
  }
}



@media only screen and (min-width: 431px) and (max-width: 680px) {
  .type_selectBackground .typeselectContainer {
    width: 65%;
  }

  .typeselectContainer .titletypeCloseBtn {
    padding: 10px 15px 0px 15px;
  }

  .typeselectContainer .titletypeCloseBtn h2 {
    font-size: 18px;
  }

  .typeselectContainer .titletypeCloseBtn button {
    font-size: 18px;
  }

  .typeselectContainer .headr_typeselect h4 {
    font-size: 13px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .video_consultation {
    width: 46%;
    padding-top: 8px;
    padding-left: 8px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .video_consultation h4 {
    font-size: 10px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .video_consultation h5 {
    font-size: 8px;
    font-weight: 400;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .onlyurgentsingle {
    width: 48%;
    padding-top: 8px;
    padding-left: 8px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .onlyurgentsingle h4 {
    font-size: 10px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .onlyurgentsingle h5 {
    font-size: 8px;
    font-weight: 400;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn {
    width: 53%;
    height: 180px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .book_appointm_btn h4 {
    font-size: 9px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .book_appointm_btn h5 {
    font-size: 7px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .free_consult_btn h4 {
    font-size: 9px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .free_consult_btn h5 {
    font-size: 7px;
  }
}




@media only screen and (min-width: 680px) and (max-width: 950px) {
  .type_selectBackground .typeselectContainer {
    width: 50%;
  }

  .typeselectContainer .titletypeCloseBtn {
    padding: 15px 20px 0px 20px;
  }

  .typeselectContainer .titletypeCloseBtn h2 {
    font-size: 22px;
  }

  .typeselectContainer .titletypeCloseBtn button {
    font-size: 22px;
  }

  .typeselectContainer .headr_typeselect h4 {
    font-size: 15px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .video_consultation {
    width: 45%;
    padding-top: 10px;
    padding-left: 10px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .video_consultation h4 {
    font-size: 12px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .video_consultation h5 {
    font-size: 10px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .onlyurgentsingle {
    width: 49%;
    padding-top: 10px;
    padding-left: 10px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .onlyurgentsingle h4 {
    font-size: 12px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .onlyurgentsingle h5 {
    font-size: 10px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn {
    width: 54%;
    height: 180px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .book_appointm_btn h4 {
    font-size: 11px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .book_appointm_btn h5 {
    font-size: 9px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .free_consult_btn h4 {
    font-size: 11px;
  }

  .typeselectContainer .headr_typeselect .main_div_inheader .next_two_btn .free_consult_btn h5 {
    font-size: 9px;
  }
}