.Delete_Background {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
}

.DelteContainer {
    width: 60%;
    border-radius: 12px;
    background-color: #eaeef3;
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin: 15px 0px 15px 0px;
}

.DelteContainer .delete_text {
    color: white;
    font-weight: bold;
    font-size: 18px;
}

.DelteContainer .imagelogoondelete {
    width: 200px;
    height: 100px;
    align-self: center;
    margin-bottom: 30px;
}

.DelteContainer .titleaddschedualCloseBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.DelteContainer .titleaddschedualCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}



/* Online or Offline */
.DelteContainer .updatebuttonContainer {
    width: 85%;
    align-self: center;
    margin: 10px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.DelteContainer .updatebuttonContainer .activeButton {
    background-color: #874DAF;
    color: white;
    padding: 12px 45px;
    border-radius: 5px;
    border: 3px solid #874DAF;
}

.DelteContainer .updatebuttonContainer .inactiveButton {
    background-color: white;
    color: #874DAF;
    padding: 12px 45px;
    border-radius: 5px;
    border: 1.5px solid #874DAF;
}

/* ///////////////////// */




/* Delete Button */
.deletebuttoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.customButton {
    width: calc(100% - 22px);
    /* width / 1.1 */
    background-color: #874DAF;
    align-self: center;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 10px 0;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: none;
    box-shadow: 0 2px 3.84px 0 rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s ease-in-out;
}

.customButton:hover {
    background-color: #6c3d8f;
}

/* ////////////////////// */





/* /////////////// */
.timedate_Dialog {
    width: 100%;
    height: 100%;
}

.timedate_Dialog .timeowninside {
    padding: 20px;
    margin: 5px 10px 0px 10px;
}

.timedate_Dialog .timedate_Ok_nd_Close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid lightgray;
    margin-top: 10px;
}

.timedate_Dialog .timedate_Ok_nd_Close .freeapoint_Ok {
    width: 100%;
    color: #874daf;
    font-weight: bold;
}

/* ....................... */


@media only screen and (min-width: 0px) and (max-width: 431px) {
    .DelteContainer {
        width: 90%;
        padding: 20px;
    }

    .DelteContainer .delete_text {
        font-size: 15px;
    }

    .DelteContainer p {
        font-size: 12px;
    }

    .DelteContainer .imagelogoondelete {
        width: 170px;
        height: 70px;
        align-self: center;
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 431px) and (max-width: 680px) {
    .DelteContainer {
        width: 83%;
        padding: 20px;
    }

    .DelteContainer p {
        font-size: 13px;
    }

    .DelteContainer .imagelogoondelete {
        width: 180px;
        height: 80px;
        align-self: center;
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 680px) and (max-width: 950px) {
    .DelteContainer {
        width: 70%;
        padding: 23px;
    }

    .DelteContainer p {
        font-size: 15px;
    }
}