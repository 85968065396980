.login_container {
    background-image: url('https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80');
    /* background-image: url('https://source.unsplash.com/800x600/?doctor'); */
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login_container .login_card {
    background-color: #fff;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
}

.login_container .login_card h2 {
    text-align: center;
    margin-bottom: 30px;
}

.login_container .login_remenbr {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px 0px 20px 6px;
}

.login_container .login_remenbr .custom_checkbox_active {
    width: 16px;
    height: 16px;
    appearance: none;
    border: 1px solid #874daf;
    border-radius: 3px;
    background-color: #874daf;
    outline: none;
    transition: background-color 0.3s;
}

.login_container .login_remenbr .custom_checkbox_active:checked::before {
    content: '\2713';
    /* Unicode checkmark symbol */
    display: block;
    color: white;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
}

.login_container .login_remenbr .custom_checkbox_inactive {
    width: 16px;
    height: 16px;
    appearance: none;
    border: 1px solid gray;
    border-radius: 3px;
    outline: none;
    transition: background-color 0.3s;
}

.login_container .login_remenbr .custom_checkbox_inactive:checked::before {
    content: '';
    display: none;
}

.login_container .login_remenbr h4 {
    color: gray;
    font-size: 15px;
    font-weight: 400;
    margin-left: 5px;
}

.login_container .login_card .form_first_group .form_control {
    padding: 12px;
    border: none;
    border-radius: 5px;
    background-color: #f5f5f5;
    width: 100%;
}

.login_container .login_card .form_first_group {
    margin-bottom: 20px;
}

.login_container .login_card .form_group .form_control {
    padding: 12px;
    border: none;
    border-radius: 5px;
    background-color: #f5f5f5;
    width: 100%;
}

.login_container .login_card .butn {
    display: block;
    width: 100%;
    background-color: #874daf;
    border: 1px solid #874daf;
    color: #fff;
    padding: 12px;
    border-radius: 5px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.login_container .login_card .butn:hover {
    border: 1px solid #874daf;
    background-color: white;
    color: #874daf;
}

.login_container .login_card .text_center {
    text-align: center;
}

.login_container .login_card .mt_3 {
    margin-top: 1rem;
}

.login_container .login_card .mt_3 a {
    color: #007bff;
    text-decoration: none;
}

.login_container .login_card .mt_3 a:hover {
    text-decoration: underline;
}



.login_container .login_card .loader_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.login_container .login_card .loader_container .loader {
    /* border: 10px solid rgba(0, 0, 0, 0.1);
    border-top-color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s ease-in-out infinite; */

    border: 16px solid #f3f3f3;
    border-top: 16px solid #874daf;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
}

@keyframes spin {

    /* to {
      transform: rotate(360deg);
    } */
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}




@media only screen and (min-width: 0px) and (max-width: 431px) {
    .login_container .login_card {
        padding: 30px;
        max-width: 300px;
    }

    .login_container .login_card h2 {
        font-size: 19px;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 431px) and (max-width: 680px) {
    .login_container .login_card {
        padding: 40px;
        max-width: 350px;
    }

    .login_container .login_card h2 {
        font-size: 20px;
        margin-bottom: 25px;
    }
}

@media only screen and (min-width: 680px) and (max-width: 950px) {
    .login_container .login_card {
        padding: 50px;
        max-width: 370px;
    }

    .login_container .login_card h2 {
        font-size: 22px;
        margin-bottom: 30px;
    }
}