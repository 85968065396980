.addinsurehidemin {
    background-color: rgba(2, 2, 2, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    position: fixed;
    height: 100%;
    z-index: 1;
}

.addinsurstart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
}

.addinsurContainer {
    width: 60%;
    border-radius: 12px;
    background-color: #eaeef3;
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin: 15px 0px 15px 0px;
}

.addinsurContainer::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}

.addinsurContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.addinsurContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
}

.addinsurContainer .backiconn {
    font-size: 22px;
    color: white;
    margin-right: 10px;
}

.addinsurContainer .adinsur_text {
    color: white;
    font-weight: bold;
    font-size: 18px;
}

/* Front And Back image */
.addinsurContainer .mainoffrontandback {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 20px;
}

.addinsurContainer .mainoffrontandback .addimagefron {
    width: 45%;
    height: 170px;
    background-color: #874DAF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    position: relative;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
}

.addinsurContainer .mainoffrontandback .addimagefron input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.addinsurContainer .mainoffrontandback .addimagefron .imageafterselect {
    width: 100%;
    height: 100%;
    object-fit: fill;
    /* object-fit: cover; */
}

.addinsurContainer .insurnbuttoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.addinsurContainer .insurnbuttoContainer .customButton {
    width: calc(100% - 22px);
    background-color: #874DAF;
    align-self: center;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 10px 0;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: none;
    box-shadow: 0 2px 3.84px 0 rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s ease-in-out;
}

.addinsurContainer .insurnbuttoContainer .customButton:hover {
    background-color: #6c3d8f;
}

/* ........ */




@media only screen and (min-width: 0px) and (max-width: 431px) {
    .addinsurContainer {
        width: 90%;
        padding: 20px;
    }

    .addinsurContainer .adinsur_text {
        font-size: 15px;
    }

    .addinsurContainer .mainoffrontandback {
        flex-direction: column;
    }

    .addinsurContainer .mainoffrontandback .addimagefron {
        width: 90%;
        height: 170px;
    }
}

@media only screen and (min-width: 431px) and (max-width: 680px) {
    .addinsurContainer {
        width: 83%;
        padding: 20px;
    }

    .addinsurContainer .mainoffrontandback {
        flex-direction: column;
    }

    .addinsurContainer .mainoffrontandback .addimagefron {
        width: 90%;
        height: 170px;
    }
}

@media only screen and (min-width: 680px) and (max-width: 950px) {
    .addinsurContainer {
        width: 70%;
        padding: 23px;
    }
}