.modal_givereviewground {
    background-color: rgba(2, 2, 2, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    position: fixed;
    height: 100%;
    z-index: 1;
}

.modal_givereviewground .modalgivereviewContainer {
    width: 35%;
    height: 510px;
    border-radius: 12px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
}

.modal_givereviewground .modalgivereviewContainer::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.modal_givereviewground .modalgivereviewContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.modal_givereviewground .modalgivereviewContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
}

.modalgivereviewContainer .titlegivereviewCloseBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 20px 0px 15px;
}

.modalgivereviewContainer .titlegivereviewCloseBtn h3 {
    margin-left: 20px;
    font-weight: 500;
}

.modalgivereviewContainer .titlegivereviewCloseBtn button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 5px;
}

.modalgivereviewContainer .cancel_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #874daf;
    padding: 12px 0px 12px 0px;
    width: 85%;
    align-self: center;
    border-radius: 7px;
    position: absolute;
    bottom: 15px;
}

.modalgivereviewContainer .cancel_btn {
    color: white;
}


@media only screen and (min-width: 0px) and (max-width: 431px) {
    .modal_givereviewground .modalgivereviewContainer {
        width: 95%;
    }
}

@media only screen and (min-width: 431px) and (max-width: 680px) {
    .modal_givereviewground .modalgivereviewContainer {
        width: 75%;
    }
}

@media only screen and (min-width: 680px) and (max-width: 950px) {
    .modal_givereviewground .modalgivereviewContainer {
        width: 55%;
    }
}