.add_schedualBackground {
    background-color: rgba(2, 2, 2, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    position: fixed;
    height: 100%;
}

.add_schedualContainer {
    width: 60%;
    height: 500px;
    border-radius: 12px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 25px;
    overflow: auto;
}

.add_schedualContainer::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}

.add_schedualContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.add_schedualContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #874daf;
}

.add_schedualContainer .titleaddschedualCloseBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.add_schedualContainer .titleaddschedualCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}



/* Online or Offline */
.add_schedualContainer .buttonContainer {
    width: 85%;
    align-self: center;
    margin: 10px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.add_schedualContainer .buttonContainer .activeButton {
    background-color: #874DAF;
    width: 22%;
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 5px;
    border: 3px solid #874DAF;
}

.add_schedualContainer .buttonContainer .inactiveButton {
    background-color: white;
    width: 22%;
    color: #874DAF;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 5px;
    border: 1.5px solid #874DAF;
}

/* ///////////////////// */



/* '''''''''''''' */
.add_schedualContainer .insidethisdatetime {
    width: 100%;
    background-color: white;
    border-radius: 15px;
    align-self: center;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 3.84px 0px rgba(0, 0, 0, 0.25);
}

.add_schedualContainer .insidethisdatetime .sevndaysindate {
    padding: 10px 0px;
}

.add_schedualContainer .insidethisdatetime .sevndaysindate .view1 {
    border: none;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: white;
}

.add_schedualContainer .insidethisdatetime .sevndaysindate .view1 .text1 {
    color: gray;
}

.add_schedualContainer .insidethisdatetime .sevndaysindate .view {
    border: none;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #874DAF;
}

.add_schedualContainer .insidethisdatetime .sevndaysindate .view .text {
    color: white;
}

/* /////////////////////// */


.add_schedualContainer .insidethisdatetime .butnodaddtim {
    border: none;
    background-color: #874DAF;
    width: 100px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-left: 10px;
    padding: 10px 0px;
    margin-top: 10px;
    border-color: #874DAF;
}

.add_schedualContainer .insidethisdatetime .timesinglediv {
    width: 100px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    display: flex;
    border: 1px solid #874DAF;
    margin: 10px 0px 5px 12px;
    padding: 10px 0px;
}

.add_schedualContainer .insidethisdatetime .timesinglediv .timedeleticon {
    color: #874DAF;
    font-size: 23px;
    margin-left: -27px;
}

.add_schedualContainer .insidethisdatetime .timesinglediv .doctrtimiinsingl {
    color: #874DAF;
    font-weight: bold;
}

/* Add Button */
.donebuttoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 7px;
}

.customButton {
    width: calc(100% - 22px);
    /* width / 1.1 */
    background-color: #874DAF;
    align-self: center;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 10px 0;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: none;
    box-shadow: 0 2px 3.84px 0 rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s ease-in-out;
}

.customButton:hover {
    background-color: #6c3d8f;
}

/* ////////////////////// */





/* /////////////// */
.timedate_Dialog {
    width: 100%;
    height: 100%;
}

.timedate_Dialog .timeowninside {
    padding: 20px;
    margin: 5px 10px 0px 10px;
}

.timedate_Dialog .timedate_Ok_nd_Close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid lightgray;
    margin-top: 10px;
}

.timedate_Dialog .timedate_Ok_nd_Close .freeapoint_Ok {
    width: 100%;
    color: #874daf;
    font-weight: bold;
}

/* ....................... */






@media only screen and (min-width: 0px) and (max-width: 431px) {
    .add_schedualContainer {
        width: 95%;
        padding: 10px;
    }

    .add_schedualContainer .buttonContainer .activeButton {
        width: 50%;
    }

    .add_schedualContainer .buttonContainer .inactiveButton {
        width: 50%;
    }

    /* '''''''''''''' */
    .add_schedualContainer .insidethisdatetime {
        padding: 10px;
    }

    .add_schedualContainer .insidethisdatetime .sevndaysindate {
        padding: 6px 0px;
    }

    .add_schedualContainer .insidethisdatetime .sevndaysindate .view1 {
        padding: 8px;
    }

    .add_schedualContainer .insidethisdatetime .sevndaysindate .view1 .text1 {
        font-size: 13px;
    }

    .add_schedualContainer .insidethisdatetime .sevndaysindate .view {
        padding: 8px;
    }

    .add_schedualContainer .insidethisdatetime .sevndaysindate .view .text {
        font-size: 13px;
    }

    /* /////////////////////// */

    .add_schedualContainer .insidethisdatetime .butnodaddtim {
        margin-left: 5px;
        padding: 10px 0px;
        margin-top: 10px;
    }


    .add_schedualContainer .insidethisdatetime .timesinglediv {
        width: 75px;
        margin: 6px 0px 5px 8px;
        padding: 6px 0px;
    }

    .add_schedualContainer .insidethisdatetime .timesinglediv .timedeleticon {
        font-size: 20px;
        margin-left: -20px;
    }

    .add_schedualContainer .insidethisdatetime .timesinglediv .doctrtimiinsingl {
        font-size: 12px;
    }
}



@media only screen and (min-width: 431px) and (max-width: 680px) {
    .add_schedualContainer {
        width: 85%;
        padding: 15px;
    }

    .add_schedualContainer .buttonContainer .activeButton {
        width: 40%;
    }

    .add_schedualContainer .buttonContainer .inactiveButton {
        width: 40%;
    }

    /* '''''''''''''' */
    .add_schedualContainer .insidethisdatetime {
        padding: 15px;
    }

    .add_schedualContainer .insidethisdatetime .sevndaysindate {
        padding: 8px 0px;
    }

    .add_schedualContainer .insidethisdatetime .sevndaysindate .view1 {
        padding: 9px;
    }

    .add_schedualContainer .insidethisdatetime .sevndaysindate .view1 .text1 {
        font-size: 13px;
    }

    .add_schedualContainer .insidethisdatetime .sevndaysindate .view {
        padding: 9px;
    }

    .add_schedualContainer .insidethisdatetime .sevndaysindate .view .text {
        font-size: 13px;
    }

    /* /////////////////////// */

    .add_schedualContainer .insidethisdatetime .butnodaddtim {
        margin-left: 5px;
        padding: 10px 0px;
        margin-top: 10px;
    }


    .add_schedualContainer .insidethisdatetime .timesinglediv {
        width: 90px;
        margin: 8px 0px 5px 10px;
        padding: 8px 0px;
    }

    .add_schedualContainer .insidethisdatetime .timesinglediv .timedeleticon {
        font-size: 22px;
        margin-left: -24px;
    }

    .add_schedualContainer .insidethisdatetime .timesinglediv .doctrtimiinsingl {
        font-size: 15px;
    }
}



@media only screen and (min-width: 680px) and (max-width: 950px) {
    .add_schedualContainer {
        width: 65%;
        padding: 20px;
    }

    .add_schedualContainer .buttonContainer .activeButton {
        width: 30%;
    }

    .add_schedualContainer .buttonContainer .inactiveButton {
        width: 30%;
    }
}