.chat_screen_container {
  display: flex;
  height: 78vh;
  margin: 25px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.chat_screen_container .chat_list_container {
  flex: 1;
  background-color: #f7f7f7;
  padding: 0px 20px 20px 20px;
}

.chat_screen_container .chat_list_container .chat_list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat_screen_container .chat_list_container .chat_list .Search_Chat {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: lightgray;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
}

.chat_screen_container .chat_list_container .chat_list .Search_Chat .searchchatinpu {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  background-color: lightgray;
  border: none;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid #e2e2e2;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: background-color 0.3s ease-in-out;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item:hover,
.chat_screen_container .chat_list_container .chat_list .chat_list_item.active {
  background-color: #e2e2e2;
  /* background-color: #ecdff5; */
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_image_container {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #874daf;
  position: relative;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_image_container img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_image_container .dotforonline {
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background-color: white;
  position: absolute;
  bottom: -2px;
  left: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_details {
  flex: 1;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_details h3 {
  font-size: 1.2rem;
  margin: 0;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_details .file_pdf_view {
  display: flex;
  gap: 5px;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_details .file_pdf_view .icon {
  color: gray;
  font-size: 17px;
}

.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_details p,
.chat_screen_container .chat_list_container .chat_list .chat_list_item .chat_list_item_details span {
  font-size: 0.9rem;
  margin: 0;
  color: #808080;
}

.chat_room_container {
  flex: 3;
  display: flex;
  flex-direction: column;
  /* position: relative; */
}

.chat_room_container .circle_top_bottom {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #874daf;
  position: absolute;
  right: 14px;
  bottom: 100px;
}

.chat_room_no_active_container {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 30px;
}

.chat_room_no_active_container img {
  width: 75%;
  height: 90%;
}

.chat_room_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #d9d9d9;
}

.chat_room_header .iconforbackinshort {
  display: none;
}

.chat-room-header-image-container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-room-header-details {
  margin-left: 10px;
}

.chat-room-header-details h3 {
  font-size: 1.2rem;
  margin: 0;
}

.chat-room-header-details span {
  font-size: 0.9rem;
  color: #808080;
}

.chat_room_header .romcaldiv {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  padding: 5px 15px 5px 15px;
  background-color: lightgray;
}

.chat_room_header .romcaldiv .romcalinsdicon {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: #f3f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.chat_room_header .romcaldiv .romcalinsdicon .finlyicon {
  color: #0751fc;
  font-size: 25px;
}


/* Chat room body */
.chat_room_container .chat_room_messages_container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.chat_room_container .chat_room_messages_container::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

.chat_room_container .chat_room_messages_container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.chat_room_container .chat_room_messages_container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #874daf;
}



.chat_room_container .chat_room_messages_container .chat_room_left_message {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
  /* float: left; */
}

.chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_message_image_container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_left_message_details {
  max-width: 80%;
}

.chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_left_message_details p {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.1rem;
  margin: 0;
  max-width: 100%;
}

.chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_left_message_details span {
  font-size: 0.7rem;
  color: #808080;
  margin-left: auto;
}

.chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_left_message_details .chat_room_message_image_views_zoom img {
  width: 200px;
  height: 200px;
  border-radius: 5%;
  object-fit: cover;
}

.chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_left_pdf_details {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.1rem;
  margin: 0;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5px;
}

.chat_room_container .chat_room_messages_container .chat_room_left_message span {
  font-size: 0.7rem;
  color: #808080;
}

/* .chat-room-message.incoming .chat_room_left_message_details {
  order: 1;
}

.chat-room-message.outgoing .chat_room_left_message_details {
  order: 2;
  text-align: right;
} */


.chat_room_container .chat_room_messages_container .chat_room_right_message {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: flex-end;
  width: 80%;
  /* background-color: pink; */
  /* float: right; */
}

.chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_message_image_container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_right_message_details {
  text-align: end;
}

.chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_right_message_details p {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.1rem;
  margin: 0;
  max-width: 100%;
}

.chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_right_message_details span {
  font-size: 0.7rem;
  color: #808080;
  margin-left: auto;
}

.chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_right_message_details .chat_room_message_image_views_zoom img {
  width: 200px;
  height: 200px;
  border-radius: 5%;
  object-fit: cover;
}

.chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_right_pdf_details {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.1rem;
  margin: 0;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5px;
}

.chat_room_container .chat_room_messages_container .chat_room_right_message span {
  font-size: 0.7rem;
  color: #808080;
}

/* .chat-room-message.incoming .chat_room_right_message_details {
  order: 1;
}

.chat-room-message.outgoing{
  background-color: pink;
  justify-content: end;
}

.chat-room-message.outgoing .chat_room_right_message_details {
  order: 2;
  text-align: right;
} */




.chat_room_input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  background-color: #f7f7f7;
}

.chat_room_input_container input {
  width: calc(100% - 100px);
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #f5f5f5;
  font-size: 16px;
  font-family: Arial, sans-serif;
  outline: none;
}

.chat_room_input_container .bottun_send {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 100px;
  background-color: #874daf;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease;
}

.chat_room_input_container #unsend {
  background-color: lightgray;
}

.chat_room_input_container .butnaftersendload {
  width: 50px;
  height: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: #874daf;
  transition: background-color 0.2s ease;
}




.chat_screen_container .chat_list_container .chat_list .loader_doctor_list {
  width: 100%;
  display: flex;
  height: 87%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chat_screen_container .chat_list_container .chat_list .loader_doctor_list p {
  margin-top: 10px;
}


.chat_room_container .loader_chat_room {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}





.custom_file_input {
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 5px;
  color: #874daf;
  margin-top: 5px;
  font-size: medium;
  transition: 0.3s ease-in-out;
  margin-right: 10px;
}

#unselectpdf {
  color: lightgray;
}

.custom_file_input input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}


.profileImage .custom_file_input:hover {
  background-color: #f1f5fc;
  color: #1a6aff;
}



@media only screen and (min-width: 0px) and (max-width: 500px) {
  .chat_room_header {
    padding: 10px;
  }

  .chat_room_header .iconforbackinshort {
    margin-right: 2px;
  }

  .chat-room-header-image-container img {
    width: 45px;
    height: 45px;
  }

  .chat-room-header-details {
    margin-left: 5px;
  }

  .chat-room-header-details h3 {
    font-size: 1rem;
  }

  .chat-room-header-details span {
    font-size: 0.7rem;
  }

  .chat_room_header .romcaldiv {
    border-radius: 5px;
    padding: 5px 8px 5px 8px;
  }

  .chat_room_header .romcaldiv .romcalinsdicon {
    width: 35px;
    height: 35px;
  }

  .chat_room_header .romcaldiv .romcalinsdicon .finlyicon {
    font-size: 22px;
  }

  .chat_room_container .chat_room_messages_container {
    padding: 10px;
  }

  .chat_room_container .chat_room_messages_container::-webkit-scrollbar {
    width: 2px;
    height: 1px;
  }

  .chat_room_container .chat_room_messages_container .chat_room_left_message {
    gap: 5px;
    margin-bottom: 10px;
  }

  .chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_message_image_container img {
    width: 35px;
    height: 35px;
  }

  .chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_left_message_details p {
    padding: 7px;
    border-radius: 7px;
    font-size: 0.9rem;
  }

  .chat_room_container .chat_room_messages_container .chat_room_left_message .chat_room_left_message_details span {
    font-size: 0.5rem;
  }


  .chat_room_input_container {
    padding: 5px 10px 5px 10px;
  }

  .chat_room_input_container input {
    width: calc(100% - 70px);
    padding: 0px;
    border-radius: 0px;
    font-size: 14px;
  }

  .chat_room_input_container .bottun_send {
    width: 40px;
    height: 40px;
    font-size: 12px;
  }


  .chat_room_container .chat_room_messages_container .chat_room_right_message {
    gap: 5px;
    margin-bottom: 10px;
  }

  .chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_message_image_container img {
    width: 35px;
    height: 35px;
  }

  .chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_right_message_details p {
    padding: 7px;
    border-radius: 7px;
    font-size: 0.9rem;
  }

  .chat_room_container .chat_room_messages_container .chat_room_right_message .chat_room_right_message_details span {
    font-size: 0.5rem;
  }
}




@media only screen and (min-width: 0px) and (max-width: 800px) {
  .chat_screen_container {
    flex-direction: column;
    margin: 20px;
  }

  .chat_screen_container .chat_list_container,
  .chat_screen_container .chat_room_container {
    flex: initial;
  }

  .chat_screen_container .chat_list_container {
    display: block;
    flex: 1;
    padding: 0px 20px 20px 20px;
  }

  .chat_screen_container .chat_room_container {
    display: none;
  }

  .chat_screen_container .chat_room_no_active_container {
    display: none;
  }

  /* Additional styles for the activeItem case */
  .chat_screen_container.active-item .chat_list_container {
    display: none;
  }

  .chat_screen_container.active-item .chat_room_container {
    flex: 1;
    display: flex;
    overflow-y: auto;
  }

  .chat_room_header .iconforbackinshort {
    display: initial;
    color: #874daf;
    margin-right: 5px;
  }
}