/* Head Dashboard */
.dashboard_Home {
  width: 100%;
  background-color: #eaeef3;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard_Home .join_searcchbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.dashboard_Home .join_searcchbar .searchbar_all {
  width: 50%;
  height: 70px;
  display: flex;
  align-items: center;
  align-self: center;
  border-radius: 10px;
  background-color: white;
  padding: 10px;
  box-shadow: 0 5px 15px rgba(32, 32, 32, .3);
}

.dashboard_Home .join_searcchbar .searchbar_all .borderline_sreachbar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  border: 2px solid #d9d9d9;
  border-color: #874daf;
  padding: 10px;
  border-radius: 8px;
}

.dashboard_Home .join_searcchbar .searchbar_all .borderline_sreachbar .searchbarinpu {
  border: none;
  height: 30px;
  width: 85%;
  font-size: 14px;
  margin-left: 10px;
  color: black;
}

.dashboard_Home .join_searcchbar .searchbar_all .borderline_sreachbar .clear_button {
  background-color: white;
  color: #874daf;
  border: 1px solid white;
  font-size: medium;
  transition: 0.3s ease-in-out;
  margin-left: 8px;
  margin-right: 8px;
}

.dashboard_Home .skelton_doctorS_div {
  width: 85%;
  align-self: center;
  margin: 20px 80px;
  border-radius: 4px;
  z-index: 0;
}

.dashboard_Home .doctor_card_ho {
  width: 85%;
  align-self: center;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 30px;
  opacity: 100px;
  box-shadow: 0 5px 15px rgba(32, 32, 32, .3);
}

.dashboard_Home .doctor_card_ho .card_inside_head {
  display: flex;
  width: 98%;
  justify-content: space-between;
  padding-left: 20px;
}

.dashboard_Home .doctor_card_ho .card_inside_head .anothercardinside {
  display: flex;
  width: 70%;
}

.dashboard_Home .doctor_card_ho .card_inside_head .prof_imag {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #874daf;
  position: relative;
}

.dashboard_Home .doctor_card_ho .card_inside_head .prof_imag img {
  width: 95%;
  height: 95%;
  border-radius: 100px;
  object-fit: cover;
}

.dashboard_Home .doctor_card_ho .card_inside_head .prof_imag .dotforonline {
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background-color: white;
  position: absolute;
  bottom: 2px;
  left: 13px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail {
  margin-left: 30px;
  width: 80%;
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .justdotdot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h3 {
  color: #4d6c82;
  text-decoration: underline;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h6 {
  color: green;
  text-decoration: underline;
  margin-left: 5px;
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h4 {
  color: black;
  font-weight: 300;
}

.dashboard_Home .doctor_card_ho .apointment_two_btn {
  display: flex;
  flex-direction: column;
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 80%;
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail .center_line {
  background-color: lightgray;
  height: 45px;
  width: 1.3px;
  margin-top: 3px;
}

.dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail h3 {
  color: black;
  text-decoration: none;
  margin-top: 3px;
  font-size: medium;
}

.apointment_two_btn {
  margin-top: 5px;
  margin-left: 38px;
}

.apointment_two_btn .submit {
  padding: 10px 40px;
  border-radius: 8px;
  margin: 5px;
  background-color: #014e78;
  color: white;
  border: 1px solid #014e78;
  font-size: medium;
  transition: 0.3s ease-in-out;
}

.apointment_two_btn #reset {
  background-color: #c02942;
  border-color: #c02942;
}

.apointment_two_btn #submite:hover {
  background-color: #f1f5fc;
  color: #014e78;
}

.apointment_two_btn #reset:hover {
  background-color: #f1f5fc;
  color: #c02942;
}



.dashboard_Home .doctor_card_ho .card_inside_botom {
  width: 35%;
  margin-left: 20px;
  margin-top: 20px;
  padding: 12px 8px 12px 8px;
  border-radius: 6px;
  border: 1px solid #014e78;
}


.dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm {
  display: flex;
  margin-bottom: 7px;
}

.dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm .sidebarIcon {
  font-size: large;
  color: black;
  font-weight: 300;
  margin-top: 2px;
}

.dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm h3 {
  color: black;
  font-weight: 400;
  margin-left: 6px;
  font-size: 16px;
}

.dashboard_Home .doctor_card_ho .card_inside_botom .secon_div_inbotm {
  display: flex;
}

.dashboard_Home .doctor_card_ho .card_inside_botom .secon_div_inbotm .sidebarIcon {
  font-size: large;
  color: black;
  font-weight: 300;
}

.dashboard_Home .doctor_card_ho .card_inside_botom .secon_div_inbotm h3 {
  color: gray;
  font-weight: 600;
  margin-left: 6px;
  font-size: 13px;
}


.dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm h4 {
  color: #029278;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}

.dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm h5 {
  color: black;
  font-size: 14px;
  font-weight: 400;
}



/* For Responsive Screen */
@media only screen and (min-width: 0px) and (max-width: 431px) {
  .dashboard_Home .join_searcchbar .searchbar_all {
    width: 75%;
    height: 50px;
    padding: 4px;
  }

  .dashboard_Home .skelton_doctorS_div {
    width: 90%;
    align-self: center;
    margin: 0px 40px;
    border-radius: 4px;
  }

  .dashboard_Home .doctor_card_ho {
    width: 90%;
    padding: 5px 0px 10px 0px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head {
    flex-wrap: wrap;
    width: 100%;
    padding-left: 10px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .anothercardinside {
    display: flex;
    flex-direction: column;
    width: 95%;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .prof_imag {
    width: 60px;
    height: 60px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .prof_imag img {
    width: 92%;
    height: 92%;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .prof_imag .dotforonline {
    width: 12px;
    height: 12px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail {
    margin-left: 3px;
    margin-top: 1px;
    width: 100%;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h3 {
    font-size: 15px;
    margin-bottom: 3px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h6 {
    font-size: 11px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h4 {
    font-size: 11px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail {
    margin-top: 5px;
    width: 100%;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail .center_line {
    height: 25px;
    width: 1.3px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail h3 {
    margin-top: 0px;
    font-size: small;
  }

  .dashboard_Home .doctor_card_ho .apointment_two_btn {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    margin-left: 0px;
  }

  .apointment_two_btn .submit {
    padding: 9px 17px;
    font-size: 11px;
  }


  .dashboard_Home .doctor_card_ho .card_inside_botom {
    width: 70%;
    margin-top: 4px;
    margin-left: 15px;
    padding: 6px;
  }


  .dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm {
    margin-bottom: 5px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm .sidebarIcon {
    font-size: small;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm h3 {
    margin-left: 5px;
    font-size: 11px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .secon_div_inbotm .sidebarIcon {
    font-size: small;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .secon_div_inbotm h3 {
    margin-left: 6px;
    font-size: 10px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm {
    margin-top: 2px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm h4 {
    font-size: 11px;
    margin-left: 3px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm h5 {
    font-size: 11px;
  }
}



/* Mobile styles */
@media only screen and (min-width: 431px) and (max-width: 680px) {
  .dashboard_Home .join_searcchbar .searchbar_all {
    width: 70%;
    height: 55px;
    padding: 6px;
  }

  .dashboard_Home .skelton_doctorS_div {
    width: 90%;
    align-self: center;
    margin: 0px 40px;
    border-radius: 4px;
  }

  .dashboard_Home .doctor_card_ho {
    width: 90%;
    padding: 5px 0px 10px 0px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head {
    flex-wrap: wrap;
    width: 100%;
    padding-left: 10px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .anothercardinside {
    display: flex;
    width: 95%;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .prof_imag {
    width: 70px;
    height: 60px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .prof_imag img {
    width: 92%;
    height: 92%;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .prof_imag .dotforonline {
    width: 13px;
    height: 13px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail {
    margin-left: 10px;
    width: 100%;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h3 {
    font-size: 16px;
    margin-bottom: 3px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h6 {
    font-size: 12px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h4 {
    font-size: 12px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail {
    margin-top: 10px;
    width: 100%;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail .center_line {
    height: 35px;
    width: 1.3px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail h3 {
    margin-top: 1px;
    font-size: small;
  }

  .dashboard_Home .doctor_card_ho .apointment_two_btn {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-left: 10px;
  }

  .apointment_two_btn .submit {
    padding: 10px 20px;
    font-size: 12px;
  }


  .dashboard_Home .doctor_card_ho .card_inside_botom {
    width: 50%;
    margin-top: 7px;
    padding: 8px 6px 8px 6px;
  }


  .dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm {
    margin-bottom: 5px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm .sidebarIcon {
    font-size: medium;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm h3 {
    margin-left: 7px;
    font-size: 13px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .secon_div_inbotm .sidebarIcon {
    font-size: medium;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .secon_div_inbotm h3 {
    margin-left: 8px;
    font-size: 11px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm {
    margin-top: 3px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm h4 {
    font-size: 12px;
    margin-left: 3px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm h5 {
    font-size: 12px;
  }
}




/* Tablet styles */
@media only screen and (min-width: 680px) and (max-width: 950px) {
  .dashboard_Home .join_searcchbar .searchbar_all {
    height: 60px;
    padding: 8px;
  }

  .dashboard_Home .doctor_card_ho {
    padding: 10px 0px 20px 0px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head {
    padding-left: 15px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .prof_imag {
    width: 80px;
    height: 80px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .prof_imag img {
    width: 93%;
    height: 93%;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .prof_imag .dotforonline {
    width: 14px;
    height: 14px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail {
    margin-left: 10px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h3 {
    font-size: 16px;
    margin-bottom: 3px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h6 {
    font-size: 13px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail h4 {
    font-size: 13px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail {
    margin-top: 10px;
    width: 100%;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail .center_line {
    height: 35px;
    width: 1.3px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_head .name_etc_detail .center_more_detail h3 {
    margin-top: 1px;
    font-size: small;
  }

  .apointment_two_btn {
    margin-top: 1px;
    margin-left: 10px;
  }

  .apointment_two_btn .submit {
    padding: 10px 20px;
    font-size: 12px;
  }


  .dashboard_Home .doctor_card_ho .card_inside_botom {
    width: 40%;
    margin-top: 10px;
    padding: 10px 6px 10px 6px;
  }


  .dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm {
    margin-bottom: 5px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm .sidebarIcon {
    font-size: medium;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .firs_div_inbotm h3 {
    margin-left: 7px;
    font-size: 14px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .secon_div_inbotm .sidebarIcon {
    font-size: medium;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .secon_div_inbotm h3 {
    margin-left: 8px;
    font-size: 12px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm {
    margin-top: 5px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm h4 {
    font-size: 13px;
    margin-left: 3px;
  }

  .dashboard_Home .doctor_card_ho .card_inside_botom .third_div_inbotm h5 {
    font-size: 13px;
  }
}