.modal_categBackground {
  background-color: rgba(2, 2, 2, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  position: fixed;
  height: 100%;
  z-index: 3;
}

.modal_categBackground .modalcategContainer {
  width: 35%;
  height: 500px;
  border-radius: 12px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
}


.modalcategContainer .titlecategCloseBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 25px 0px 25px;
}

.modalcategContainer .titlecategCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}


.modalcategContainer .all_category_inside {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.modalcategContainer .all_category_inside::-webkit-scrollbar {
  width: 12px;
  height: 4px;
}

.modalcategContainer .all_category_inside::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.modalcategContainer .all_category_inside::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #874daf;
}

.modalcategContainer .single_category {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 30px 10px 30px;
}

.modalcategContainer .single_category .cirecle_category {
  width: 55px;
  height: 55px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #874daf;
}

.modalcategContainer .single_category .cirecle_category .category_Icon {
  width: 45px;
  height: 45px;
  object-fit: contain;
  /* filter: brightness(5000) saturate(0); */
}

.modalcategContainer .single_category h5 {
  color: gray;
  font-weight: 400;
}




@media only screen and (min-width: 0px) and (max-width: 431px) {
  .modal_categBackground .modalcategContainer {
    width: 90%;
  }

  .modalcategContainer .titlecategCloseBtn {
    padding: 12px 18px 0px 18px;
  }

  .modalcategContainer .titlecategCloseBtn h2 {
    font-size: 18px;
  }

  .modalcategContainer .titlecategCloseBtn button {
    font-size: 18px;
  }

  .modalcategContainer .all_category_inside::-webkit-scrollbar {
    width: 8px;
  }

  .modalcategContainer .single_category {
    padding: 7px 18px 7px 18px;
  }

  .modalcategContainer .single_category .cirecle_category {
    width: 45px;
    height: 45px;
  }

  .modalcategContainer .single_category .cirecle_category .category_Icon {
    width: 35px;
    height: 35px;
  }

  .modalcategContainer .single_category h4 {
    font-size: 13px;
  }

  .modalcategContainer .single_category h5 {
    font-size: 11px;
  }
}




@media only screen and (min-width: 431px) and (max-width: 680px) {
  .modal_categBackground .modalcategContainer {
    width: 60%;
  }

  .modalcategContainer .titlecategCloseBtn {
    padding: 15px 20px 0px 20px;
  }

  .modalcategContainer .titlecategCloseBtn h2 {
    font-size: 20px;
  }

  .modalcategContainer .titlecategCloseBtn button {
    font-size: 20px;
  }

  .modalcategContainer .all_category_inside::-webkit-scrollbar {
    width: 10px;
  }

  .modalcategContainer .single_category {
    padding: 8px 20px 8px 20px;
  }

  .modalcategContainer .single_category .cirecle_category {
    width: 50px;
    height: 50px;
  }

  .modalcategContainer .single_category .cirecle_category .category_Icon {
    width: 40px;
    height: 40px;
  }

  .modalcategContainer .single_category h4 {
    font-size: 14px;
  }

  .modalcategContainer .single_category h5 {
    font-size: 12px;
  }
}



@media only screen and (min-width: 680px) and (max-width: 950px) {
  .modal_categBackground .modalcategContainer {
    width: 50%;
  }
}